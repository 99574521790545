/*
Landing Hero

The landing page side-by-side hero

Markup:
.landing
  .landing--hero
    .landing__content
      .row
        .col6.landing__left
          h2 MindTap for Biology, Effective Course Materials, All in One Place
          p Interact with a sample chapter from Russell's Biology the Dynamic Science 4th edition in the MindTap Reacher, and learn more about the text. Try your hand at some of the activities and assessments that are included in MindTap.
          .landing__buttons
            a.btn.btn--large.btn--orange(href="/sample/") Try MindTap for Biology
            a.btn.btn--large.btn--more(href="#tours") Explore MindTap's Features
              span.icon.icon--down
        .col6.landing__right
          a.google-play(href="#")
            img(src="/images/landing/google-play.png",width="143",height="46")
          a.app-store(href="#")
            img(src="/images/landing/app-store.png",width="143",height="46")
          .landing__layout
            img.layout__screen(src="/images/landing/screen.jpg",width="438",height="264")
            img.layout__cover(src="/images/isbn_resources/9781305389892.jpg",width="192",height="241")
            .layout__meta
              p Biology: The Dynamic Science 4th Edition
              small Peter J. Russell | Paul E. Hertz
  .landing--tours
    .landing__content
      h3
        a(name="tours") Explore MindTap's Features
      .link-list
        a(href="#").link-list__item
          img(src="http://placehold.it/200x200",width="200",height="200")
          small Personalize The Learning Path
          p Engage your students your way
        a(href="#").link-list__item
          img(src="http://placehold.it/200x200",width="200",height="200")
          small Real-time Analytics
          p Give students the support and confidence to succeed
        a(href="#").link-list__item
          img(src="http://placehold.it/200x200",width="200",height="200")
          small Mobile And Convenient
          p Put all the tools they'll need to succeed in one place
        a(href="#").link-list__item
          img(src="http://placehold.it/200x200",width="200",height="200")
          small Rich Activities To Apply Concepts
          p Helping students master conceptual understanding
        a(href="#").link-list__item
          img(src="http://placehold.it/200x200",width="200",height="200")
          small Personalizied Services
          p Service and support every step of the way
        a(href="#").link-list__item
          img(src="http://placehold.it/200x200",width="200",height="200")
          small Think Like A Biologist
          p Emphasize and apply the process of science.

Styleguide 2.4.

*/


.btn.btn--more {
  padding-right: 20px;
  span {
    margin-left: 10px;
    font-size: 14px;
  }
}

.device-iOS, .device-android {
  .landing__buttons .btn {
    display: none;
  }
  .show-mobile {
    display: block;
  }
  .show-non-mobile {
    display: none;
  }
}

.device-iOS .landing {
  .app-store {
    display: block;
    text-align: center;
    margin: auto;
  }
}

.device-android .landing {
  .google-play {
    display: block;
    text-align: center;
    margin: auto;
  }
}


.landing {
  background-color: #0070b4;
  background-image: linear-gradient(to bottom,#0070b4,#023158);
  overflow: hidden;
  @media #{$desktop-up} {
    padding-top: 70px;
  }
  .google-play, .app-store {
    display: none;
    img {
      display: inline-block;
    }
  }
  &__buttons {
    display: none;
    @media #{$desktop-up} {
      display: block;
    }
    a {
      margin: 5px 0;
      display: block;
    }
    @media screen and (min-width: 1000px) {
      text-align: center;
      a {
        display: block;
        width: 400px;
      }
    }
    @media screen and (min-width: 1295px) {
      display: flex;
      a {
        margin: 5px;
        &:first-child {
          margin-left: 0;
        }
      }
    }
  }
  &__left {
    h1, h2 {
      font-weight: 300;
    }
    text-align: center;
    @media screen and (min-width: 1000px) {
      text-align: left;
    }
  }
  &__right {
    text-align: center;
    @media screen and (min-width: 1000px) {
      text-align: right;
    }
  }
  .show-mobile {
    display: none;
  }
  .show-non-mobile {
    display: block;
  }
  &__img-desktop {
  	  margin:auto;
  }
  &__img-mobile {
    display: block;
    margin:auto;
    @media #{$desktop-up} {
      display: none;
    }
  }

  &--hero {
    padding: 30px 0 3px 0;
    overflow: hidden;
    font-weight: 300;
    @media #{$desktop-up} {
      padding: 0 0 130px 0;
      overflow: visible;
      //padding: 0 30px 3px 30px;
    }
    h1,h2,p {
      color: #fff;
    }
    h1 {
      font-size: 28px;
    }
    h2 {
      font-size: 18px;
    }
    h1,h2 {
      line-height: 1.333em;
      margin: 0;
    }
    @media #{$desktop-up} {
      h1,h2 {
        font-size: 46px;
      }
    }
    p {
      margin: 1em 0;
      font-size: 16px;
      line-height: 1.6em;
    }
  }
  .or-explore {
    color: #fff;
    a {
      text-decoration: underline;
    }
  }
  &--tours {
    h3 {
      font-size: 22px;
      color: #fff;
      text-align: center;
      @media #{$desktop-up} {
        font-size: 30px;
      }
    }
  }
  &__content {
    box-sizing: border-box;
    padding: 30px 20px;
    @media #{$desktop-up} {
      padding: 50px 20px;
    }
  }
  @media #{$desktop-up} {
    &__content {
      //max-width: 40em;
    }
  }
}

.landing__layout {
  position: relative;
  margin: 30px 0;
  display: inline-block;
  text-align: left;
  @media #{$desktop-up} {
    padding-left: 50px;
    margin: 0 30px 0 0;
  }
  .layout {
    &__screen {
      box-shadow: 7px 15px 16px rgba(0,0,0,0.5);
      position: absolute;
      max-width: none;
      left: -10px;
      border-radius: 2px;
      @media #{$desktop-up} {
        left: auto;
        right: -20px;
        max-width: 100%;
        position: relative;
        margin-bottom: 60px;
      }
    }
    &__cover {
      box-shadow: 7px 15px 16px rgba(0,0,0,0.24);
      position: relative;
      margin-top: 60px;
      left: -45px;
      @media #{$desktop-up} {
        margin-top: 0;
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }
    &__meta {
      text-align: center;
      margin-top: 20px;
      @media #{$desktop-up} {
        margin-top: auto;
        text-align: left;
        position: absolute;
        left: 210px;
        top: 280px;
      }
      p {
        font-size: 14px;
        margin: 0;
      }
      small {
        font-size: 12px;
        color: #fff;
      }
    }
  }
}

.landing-dicipline__layout {
  margin: 50px auto 0 auto;
  position: relative;
  text-align: center;
  padding: 100px 0;
  height: 273px;
  width: 360px;
  img {
    max-width: none;
  }
  @media #{$desktop-up} {
    text-align: left;
    padding: 0 0 0 50px;
    margin: 0 30px 0 0;
    height: auto;
    width: auto;
  }
  .layout {
    &__lookinside {
      position: absolute;
      top: -15px;
      left: -115px;
    }
    &__screen {
      position: absolute;
      max-width: none;
      left: 50%;
      width: 460px;
      transform: translateX(-50%);
      right: 0;
      top: 0;
      @media #{$desktop-up} {
        right: 20px;
        left: 0;
        width: auto;
        transform: none;
        position: absolute;
        margin-bottom: 60px;
      }
    }
    &__cover {
      box-shadow: 7px 15px 16px rgba(0,0,0,0.24);
      position: absolute;
      display: block;
      left: 30px;
      width: 80.5px;
      height: 101px;
      @media #{$desktop-up} {
        top: 131px;
        left: 105px;
        width: 161px;
        height: 202px;
        box-shadow: 7px 15px 16px 0 rgba(0,0,0,0.19);
      }
    }
    &__meta {
      text-align: left;
      margin-top: 0px;
      color: #3F3F3F;
      left: 140px;
      padding-right: 30px;
      position: absolute;
      small {
        font-size: 12px;
      }
      .layout__authors {
        font-size: 12px;
      }
      .layout__title {
        font-size: 14px;
        font-weight: bold;
      }
      .layout__feature {
        font-size: 12px;
        font-style: italic;
        font-weight: 600;
        color: #0178B1;
      }
      h1, h2, p, small {
        color: #3F3F3F;
      }
      @media #{$desktop-up} {
        margin-top: auto;
        padding-right: 0;
        position: absolute;
        left: 297px;
        top: 163px;
        h1, h2 {
          margin-bottom: 9px;
        }

      }
      p {
        font-size: 14px;
        margin: 0;
      }
    }
  }
}
.landing-other {
    background: #fff;
    padding: 30px 0;
    display: none;
    @media #{$desktop-up} {
      display: block;
    }
    // height: 300px;
    &__headline {
      font-size: 30px;
      line-height: 56px;
      text-align: center;
    }
}

.landing-others {

  &__item {
    text-align: center;
    padding: 20px 50px 15px 50px;
  }
  &__cover {
    width: 103px;
    height: 130px;
    display: block;
    margin: auto;
  }
  &__title {
    font-size: 14px;
    line-height: 24px;
    font-weight: bold;
  }
  &.link-list {
    .slick-dots {
      margin: auto;
      .slick-active button {
        background-color: #007db8 !important;
      }
    }
    button.slick-prev {
      left: 10px;
      top: calc(50% - 70px);
    }
    button.slick-next {
      right: 10px;
      top: calc(50% - 70px);
    }
  }
}


.list-list-holder > div, .link-list {
  text-align: center;
  @media #{$desktop-up} {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-content: center;
  }
  .link-list__item {
    padding: 30px;
    margin-top: 30px;
    background: #fff;
    border-radius: 3px;
    display: block;
    position: relative;
    @include color(grey);
    text-decoration: none;
    min-height: 366px;
    @media #{$desktop-up} {
      box-shadow: 6px 2px 16px 12px rgba(0,0,0,0.12);
      width: calc(33.333333% - 20px);
    }
    .list-link__meta {
      position: relative;
      transition: margin-top ease-in-out 0.2s;
    }
    .btn {
      margin-top: 20px;
    }

    @media #{$desktop-up} {
      &:hover {
        text-decoration: none;
        .list-link__meta {
          margin-top: -40px;
        }
        img {
          opacity: 0.4;
        }
        .btn {
          opacity: 1;
        }
      }
      .btn {
        opacity: 0;
        position: absolute;
        margin: 0;
        bottom: 20px;
        left: 50%;
        margin-left: -69px;
      }
    }
    img {
      margin: 0 auto 20px auto;
      transition: opacity ease-in-out 0.2s;
    }
    small {
      display: block;
      text-transform: uppercase;
      font-size: 12px;
      @include color(grey);
    }
    p {
      font-weight: 600;
      color: $brand-primary;
      font-size: 18px;
      line-height: 1.3em;
      @media #{$desktop-up} {
        font-size: 22px;
      }
      margin: 0;
    }
  }
}

.list-link__cta {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  white-space: nowrap;
  a.btn {
    opacity: 1!important;
    position: relative!important;
    left: auto!important;
    margin-left: auto!important;
    bottom: auto!important;
    margin-top: 20px!important;
  }
}
