.search-page-content {
  &:before,
  &:after {
    content: '';
    display: table;
  }
  &:after {
    clear: both;
  }
  margin-bottom: 60px;
  padding-top: 30px;
  padding-bottom: 10px;
  .screen-reader-text {
    position: absolute;
    margin-left: -9999px;
  }

  input[type=text] {
    width: calc(100% - 130px);
    font-size: 14px;
    padding: 6px 3px 6px 30px;
    float: left;
  }
  .btn {
    float: right;
  }
  .searchform {
    position: relative;
    &:after {

      position: absolute;
      top: 8px;
      left: 8px;
      @include color(grey);
      content: "\EA13";
      font-family: 'coastblog-icons' !important;
      font-style: normal !important;
      font-weight: normal !important;
      font-variant: normal !important;
      text-transform: none !important;
      speak: none;
      line-height: 1;
      vertical-align: middle;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  }
  @media #{$desktop-up} {
    padding-top: 40px;
    padding-bottom: 10px;
  }
}
