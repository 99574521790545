/*
Buttons

Buttons

Markup:
<a href="" class="btn {$modifiers}">Button</a>

.btn--hollow	    - Hollow button
.btn--hollow-white	- Hollow button
.btn--blue		    - Blue button
.btn--block		    - Block button
.btn--small		    - Small button
.btn--plain		    - Plain button

Styleguide 1.3.

*/



// Button Mixins
@mixin btn() {
	@include color(white);
	@include backgroundColor(green);
	@include transition;
	border-width: 0;
	border-style: none;
	font-size: 14px;
	font-weight: 600;
	display: inline-block;
	text-align: center;
	padding: 7px 20px;
	height: auto;
	text-decoration: none;
	border-radius: 3px;
	cursor: pointer;
	border-color: transparent;
	white-space: normal;
	& > span.icon {
  	margin-right: 10px;
  	font-size: 0.6em;
  	line-height: 1;
  	position: relative;
  	top: -1px;
	}
	&:active {
		box-shadow: none;
	}
	@media #{$desktop-up} {
		&:hover {
			background-color: lighten(map-get($colors,green),5%);
		}
		&:active {
			background-color: darken(map-get($colors,green),5%);
		}
	}

}

@mixin btn--hollow() {
	@include btn;
	padding: 6px 29px 6px;
	background: #fff;
	@include color(green);
	border: 1px solid map-get($colors,green);
	box-shadow: none;
	@media #{$desktop-up} {
		&:hover {
			@include backgroundColor(blue);
			@include color(white);
			border-color: transparent;
			text-decoration: underline;
		}
	}
}


.btn {
	@include btn;
	&--secondary {
  	background-color: $brand-primary;
  	&:hover {
    	background-color: lighten($brand-primary,10%);
  	}
  	&:active {
    	background-color: darken($brand-primary,10%);
  	}
	}
	&--link {
  	background-color: transparent;
  	color: $brand-primary;
  	border: none;
  	font-weight: normal;
  	&:hover {
    	background-color: transparent;

  	}
	}
	&--orange {
  	background-color: #FA6600;
  	&:hover {
    	background-color: lighten(#FA6600,10%);
  	}
		&:active {
			background-color: darken(#FA6600,5%);
		}
	}
	&--hollow {
		@include btn--hollow;
		&.btn--large {
  		padding: 12px 49px 14px;
  		margin: 15px;
		}
		&:hover {
  		background-color: darken(map-get($colors,green),5%);
			@include color(white);
		}

		&.btn--secondary {
      border-color: $brand-primary;
      color: $brand-primary;
      &:hover {
        background: transparent;
      }
		}
	}
	&--small {
  	padding: 4px 17px 5px;
	}
	&--large {
  	padding: 13px 30px 15px;
  	font-size: 16px;
	}
}
