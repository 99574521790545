* {
  box-sizing: border-box;
}
body {
  margin: 0;
  padding: 80px 0 0 0;
  font-family: sans-serif;
}

img {
  max-width: 100%;
  height: auto;
  border: none;
}

.hidden-mobile {
  @media(max-width: 1023px) {
    display: none;
  }
}

.video-16x9 {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
