/*
Other Modal

Other Textbooks modal

Markup:
.modal
  .modal__backdrop
  .modal__body
    .modal__header
      h1.modal__headline Biology Textbooks MindTap Supports
    .modal__content
      ul.book-list
        li.book-list__item
          .book-meta
            img.book-meta__cover(src="/images/isbn_resources/1111577730/cover.jpg",width="80",height="97")
            h2.book-meta__title Biology: The Dynamic Science 4th Edition
            p.book-meta__author by Peter J. Russel and Paul E. Hertz
            strong.book-meta__isbn ISBN: 1111577730
        li.book-list__item
          .book-meta
            img.book-meta__cover(src="/images/isbn_resources/1111577730/cover.jpg",width="80",height="97")
            h2.book-meta__title Biology: The Dynamic Science 4th Edition
            p.book-meta__author by Peter J. Russel and Paul E. Hertz
            strong.book-meta__isbn ISBN: 1111577730
        li.book-list__item
          .book-meta
            img.book-meta__cover(src="/images/isbn_resources/1111577730/cover.jpg",width="80",height="97")
            h2.book-meta__title Biology: The Dynamic Science 4th Edition
            p.book-meta__author by Peter J. Russel and Paul E. Hertz
            strong.book-meta__isbn ISBN: 1111577730
        li.book-list__item
          .book-meta
            img.book-meta__cover(src="/images/isbn_resources/1111577730/cover.jpg",width="80",height="97")
            h2.book-meta__title Biology: The Dynamic Science 4th Edition
            p.book-meta__author by Peter J. Russel and Paul E. Hertz
            strong.book-meta__isbn ISBN: 1111577730

Styleguide 2.7.0

*/

.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  &__backdrop {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
  }
  &__body {
    background: #fff;
    position: absolute;
    width: calc(100% - 50px);
    max-width: 80em;
    transform: translate(-50%,-50%);
    left: 50%;
    top: 50%;
    border-radius: 3px;
    height: 90%;
    &.model__body-fluid {
      height: auto;
      max-height: 90%;
    }    
  }

  &__close {
    position: absolute;
    top: 23px;
    right: 23px;
    text-decoration: none;
    cursor: pointer;
    @include color(grey);
    a {
      @include color(grey);
      text-decoration: none;
    }
  }
  &__header {
    width: 100%;
    background: #F8F8F8;
    border-bottom: 1px solid #ddd;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }
  &__headline {
    font-size: 22px;
    text-align: center;
    margin: 0;
  }
  &--skinny {
  	.modal__body {
  		max-width: 40em;
  	}
  }
  &--enter {
  	opacity: 0;
  	.modal__body {
  		top: 60%;
  	}
  }
  &--enter-active {
  	opacity: 1;
    transition: opacity 250ms ease;
    .modal__body {
    	top: 50%;
    	transition: top 100ms ease;
    }
	}
  &--leave {
  	opacity:1;
  	.modal__body {
  		top: 50%;
  	}
  }
  &--leave-active {
  	opacity:0;
  	transition: opacity 250ms ease;
  	.modal__body {
  		top: 60%;
     	transition: top 100ms ease;
 		}
  }
  &__content {
    height: calc(100% - 75px);
    overflow: auto;
  }
}

.book-list {
  list-style: none;
  margin: 0;
  padding: 0;
  &__item {
    padding: 0;
    border-top: 1px solid #ddd;
    min-height: 150px;
    &:first-child {
      border-top: 1px solid transparent;
    }
  }
  .book-meta__title {
    font-size: 16px;
  }
  .book-meta {
    padding-left: 125px;
    &__cover {
      left: 20px;
    }
    &__author {
      font-size: 14px;
    }
    
  }
}

.styleguide-element .modal {
  position: relative;
  &__backdrop {
    position: relative;
  }
  &__body {
    position: relative;
    transform: none;
    left: 0;
    top: 0;
    width: 100%;
  }
  &__content {
    position: relative;
  }

}
/*
TOC Modal

Table of contents modal

Markup:
.modal
  .modal__backdrop
  .modal__body
    .modal__close
      a.icon.icon--close
    .modal__header
      h1.modal__headline Table of Contents
    .modal__content
      ol.toc-list
        h3.toc-list__section Introduction
        li Introduction to Biological Concepts and Research.
        h3.toc-list__section Unit One: Molecules and Cells
        li Life, Chemistry, and Water.
        li Biological Molecules: The Carbon Compounds of Life.
        li Cells.
        li Membranes and Transport.
        li Energy, Enzymes, and Biological Reactions.
        li Photosynthesis.
        li Cell Communication.
        li Cell Division and Mitosis.
        h3.toc-list__section Unit Two: Genetics
        li Melosis: The Cellular Basis of Sexual Reproduction.
        li Mendel, Genes, and Inheritance.
        li Genes, Chromosomes, and Human Genetics.

Styleguide 2.7.1

*/

.toc-list {
  li {
    margin: 5px 0 5px 40px;
  }
}

.toc-list__section {
  font-size: 16px;
  margin: 1em 0 .5em;
}

/*
Video Modal

MindTap Video Features Modal

Markup:
.modal
  .modal__backdrop
  .modal__body
    .modal__header
      h1.modal__headline MindTap Reader Features
    .modal__content
      .row.row-no-margins
        .col6
          .videoWrapper
            iframe(width="640",height="360",src="https://www.youtube.com/embed/dQw4w9WgXcQ",frameborder="0",allowfullscreen)
        .col6
          h3.feature--title These features allow you to:
          ul.features-list
            li Empower stuents with the ability to create their own study guide
            li Build and personalize your course to teach biology the way you want to.
            li Offer additional resources to help students with difficult concepts.
          h3.feature--title See MindTap in Action:
          ul.action-list
            li
              span Highlight Text
            li.active
              span Adding a Note
            li
              span Adding a Video
            li
              span Bookmark a Page
            li
              span Text-to-Speech

Styleguide 2.7.2

*/

.feature--title {
  @include color(grey);
}

.features-list {
  list-style: none;
  padding: 0;
  li {
    font-size: 12px;
    margin: 5px 0;
    padding-left: 22px;
    padding-right: 10px;
    position: relative;
    &:before {
      @include icon();
      content: "\EA09";
      margin-right: 8px;
      color: #90B82E;
      position: absolute;
      left: 0;
      top: 6px;
    }
  }
}

.videoWrapper {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
	height: 0;
}
.videoWrapper iframe,.videoWrapper video {
	position: absolute;
	top: 0;
	left: 0;
	width: calc(100% - 30px);
	height: 100%;
}

.action-list {
  list-style: none;
  padding: 0;
  li {
    margin: 7px 0;
  }
  a {
   	text-decoration: none;
    color: $brand-primary;
    font-size: 14px;
    font-weight: bold;
    &:before {
      @include icon();
      content: "\EA11";
      margin-right: 8px;
      position: relative;
      top: -1px;
    }
    &.active {
      color: #000;
    }
  }
}
