@import "./node_modules/susy/sass/susy";

$grid-width: 80em;
$grid-columns: 12;

$susy: (
  columns: $grid-columns, // The number of columns in your grid
  gutters: 27px/78px, // The size of a gutter in relation to a single column
  gutter-position: split,
  global-box-sizing: border-box
);


.container {
  position: relative;
  @include container($grid-width);
}
.row {
	&:before,
	&:after {
		display: table;
		content: '';
	}
	&:after {
		clear: both;
	}
}


$cols: (
  col2:2,
  col3:3,
  col4:4,
  col5:5,
  col6:6,
  col7:7,
  col8:8,
  col9:9,
  col10:10,
  col12:12
);



@each $key, $count in $cols {
  .#{$key} {
    // @include span(12 of 12);
    padding: 0 20px;
    @media #{$desktop-up} {
      padding: 0;
      @include span($count of 12);
    }
  }
}


$offsetCols: (
  col1-offset:1,
  col2-offset:2,
  col3-offset:3,
  col4-offset:4,
  col5-offset:5,
  col6-offset:6,
  col8-offset:8,
  col12-offset:12
);


@each $key, $count in $offsetCols {
  .#{$key} {
    // @include respond-to(tablet) {
    	@media #{$desktop-up} {
      	@include push($count of 12);
      }
    // }
  }
}


.wrap {
  padding-bottom: 30px;
  @media #{$desktop-up} {
    padding-bottom: 120px;
  }
}

.wrap-outer {
  position: relative;
  z-index: 3;
  background-color: #FFF;
  box-shadow: 0 10px 20px -10px #C2C4C6;
  @media #{$desktop-up} {
    box-shadow: none;
  }
}

$currentGutter: (
  gutter()
);

$susy: (
  columns: $grid-columns, // The number of columns in your grid
  gutters: 0, // The size of a gutter in relation to a single column
  gutter-position: split,
  global-box-sizing: border-box
);

.row-no-gutters {
  margin-left: $currentGutter;
  margin-right: $currentGutter;
}

.row-no-gutters,.row-no-margins {
  @each $key, $count in $cols {
    .#{$key} {
      @include span($count of 12);
      margin-left: 0;
      margin-right: 0;
    }
  }
}
