/*
Sampe page

The landing page for the sample excluding any of the sample chapter or assessment markup

Markup:
.container
  .row
    .col8
      a.link-back(href="/") Learn more about MindTap for Biology
      .book-meta
        img.book-meta__cover(src="/images/isbn_resources/1111577730/cover.jpg",width="101",height="122")
        small.book-meta__mice Featured Digital Sample
        h2.book-meta__title Biology: The Dynamic Science 4th Edition
        p.book-meta__author by Peter J. Russel and Paul E. Hertz
        strong.book-meta__isbn ISBN: 1111577730
        ul.book-meta__links
          li
            a View TOC
          li
            a View other biology textbooks
    .col4
      .login-cta
        h4 Access the full book in MindTap right now!
        a.btn.btn--large(href="") Log in to MindTap
        p Don't have an account?
          a(href="") Sign up now

Styleguide 2.5.0

*/

.sample-header {
  margin-bottom: 40px;
  margin-top: 40px;
}
.link-back {
  color: $brand-primary;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  position: relative;
  padding-left: 16px;
  &:hover {
    text-decoration: underline;
  }
  &:before {
    content: '\EA05';
    font-family: 'cengage-icons' !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    vertical-align: middle;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 11px;
    position: absolute;
    margin-right: 10px;
    left: 0;
    top: 7px;
    text-decoration: none!important;
  }
}

.book-meta {
  margin-top: 20px;
  position: relative;
  padding-left: 130px;
  line-height: 1.8em;
  &__cover {
    position: absolute;
    left: 0;
    top: 6px;
    max-width: 101px;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.15);
  }
  &__mice {
    display: block;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 12px;
    @include color(grey);
  }
  &__title {
    margin: 0 0 5px 0;
    line-height: 1.2em;
    
  }
  &__author {
    margin: 0;
    font-size: 14px;
    @include color(charcoal);
  }
  &__isbn {
    margin: 0;
    font-weight: 600;
    font-size: 14px;
  }
  &__links {
     list-style: none;
     margin: 5px 0 0 0;
     padding: 0;
     line-height: 1em;
     font-size: 14px;
     li {
       display: inline-block;
       padding: 0 5px 0 8px;
       border-left: 1px solid map-get($colors, grey);
       &:first-child {
         border-left: none;
         padding-left: 0;
       }
       a {
         color: $brand-primary;
         text-decoration: none;
         &:hover {
           text-decoration: underline;
         }
       }
     }
  }
}

.login-cta {
  background-color: map-get($colors, offwhite);
  padding: 30px;
  text-align: center;
  h4 {
    margin: 0 50px 20px 50px;
  }
  p {
    margin-bottom: 0;
    font-size: 14px;
    padding: 0 50px;
    a {
      color: $brand-primary;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

/*
Interactive Samples

The interactive eReader and Assessment tools tab activation

Markup:
.container
  .row
    .col4
      ul.tool-list
        li.active
          a(href="#")
            span.icon.icon--book
            strong Sample Chapter
            p Check out a sample chapter from the MindTap Reader.
        li
          a(href="#")
            span.icon.icon--grad-hat
            strong Assessments
            p Try a sample activity with this assessments demo.
      hr.thin-stroke
      .mindtap-benefits
        h5.mindtap-benefits__title Benefits of MindTap for Biology
        ul.mindtap-benefits__video-list
          li
            a Personalized Support
          li
            a Realtime Analytics
          li
            a Mobile Optimized
          li
            a Conceptual Learning
          li
            a Specialized Learning Paths

Styleguide 2.6.0

*/

.tool-list {
  list-style: none;
  padding: 0;
  margin: 0;
  li {
    a {
      display: block;
      position: relative;
      padding: 20px 20px 20px 70px;
      text-decoration: none;
      display: block;
      @include color(black);
      &:hover {
        background-color: #F7F7F7;
      }
      &.active {
        background: #DFDFDF;
        .icon,strong {
          @include color(black);
        }
        &:after {
          content: '';
          display: block;
          position: absolute;
          right: -25px;
          top: calc(50% - 25px);
          width: 0;
          height: 0;
          border-top: 25px solid transparent;
          border-bottom: 25px solid transparent;
          border-left: 25px solid #DFDFDF;
          z-index: 1;     
        }
      }      
    }
    .icon,strong {
      color: $brand-primary;
    }

  }
  .icon {
    position: absolute;
    left: 20px;
    top: 23px;
    font-size: 25px;
  }
  p {
    margin: 5px 0;
    font-size: 12px;
  }
}

.thin-stroke {
  border: none;
  border-top: 1px solid map-get($colors, offwhite);
}

.mindtap-benefits {
  margin: 30px 20px;
  
  &__title {
    @include color(grey);
    text-transform: uppercase;
    font-size: 12px;
    margin: 0;
  }
  &__video-list {
    list-style: none;
    margin: 10px 0;
    padding: 0;
    a {
      color: $brand-primary;
      font-weight: 600;
      text-decoration: none;
      line-height: 32px;
      display: block;
      &:hover {
        text-decoration: underline;
      }
    }
    small {
      font-size: 14px;
    }  
    
  }
}



.interactive-tools {
  background: #3F3F3F;
  padding: 30px 50px 0 50px;
  display: none;
  color: #fff;
  position: relative;
  &__header {
    margin-bottom: 30px;
  }
  &__title {
    margin: 0;
  }
  &__sub {
    font-size: 14px;
    font-weight: 600;
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      li {
        border-left: 1px solid #fff;
        padding: 0 10px;
        display: inline-block;
        &:first-child {
          border-left: none;
          padding-left: 0;
        }
        a {
          text-decoration: underline;
          cursor: pointer;
          &.active {
            text-decoration: none;
          }
        }
      }
    }
  }

  a.interactive-tools__sub {
    text-decoration: underline;
    cursor: pointer;
  }
  &.active {
    display: block;
  }
  .btn {
    margin-top: 10px;
  }
  iframe {
    min-width: 100%;
    height: 700px;
    border: none;
  }  
}


