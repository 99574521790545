/*
Tours

The tour modal section

Markup:
.tours
  .tours__carousel
    .tours__list
      .tours__item
        .row
          .col12.text-center
            h1.tours__title Mobile Optimized
            p.tours__page 1 of 5
        .row 
          .col5.col1-offset
            .tours__description
              h2.tours__intro Heather’s running late, and has a test.
              p.tours__text Since the MindTap reader is optimized for her handheld device, she can squeeze every minute out of her short bus ride, and study along the way.
          .col5.text-right
            img.tours__img(src="/images/tours/ph-1.jpg",width="458",height="458")
      .tours__item
        .row
          .col12.text-center
            h1.tours__title Mobile Optimized
            p.tours__page 2 of 5
        .row 
          .col5.col1-offset
            .tours__description
              h2.tours__intro Your quiz covers 200 pages. Heather has 20 minutes.
              p.tours__text That’s why she used MindTap on her tablet last night to bookmark the things she needs to spend more time on. Now she can quickly study from a select collection of pages.
          .col5.text-right
            img.tours__img(src="/images/tours/ph-1.jpg",width="458",height="458")
      .tours__item
        .row
          .col12.text-center
            h1.tours__title Mobile Optimized
            p.tours__page 3 of 5
        .row 
          .col5.col1-offset
            .tours__description
              h2.tours__intro Heather knows the bus isn’t a library. 
              p.tours__text Today it will have to do, and with MindTap’s hands free text-to-speech feature and headphones, she can hold on tight and tune out the baby beside her at the same time.
          .col5.text-right
            img.tours__img(src="/images/tours/ph-1.jpg",width="458",height="458")
      .tours__item
        .row
          .col12.text-center
            h1.tours__title Mobile Optimized
            p.tours__page 4 of 5
        .row 
          .col5.col1-offset
            .tours__description
              h2.tours__intro Heather’s lifestyle is fast paced and hectic.
              p.tours__text But she’s still at the top of her class. The workflow & learning styles of students like heather are best suited to a single, easy-to-navigate platform with realtime access to help.
          .col5.text-right
            img.tours__img(src="/images/tours/ph-1.jpg",width="458",height="458")
      .tours__item
        .row
          .col12.text-center
            h1.tours__title Mobile Optimized
            p.tours__page 5 of 5
        .row
          .col6.col3-offset
            .tours__cta.text-center
              h2.tours__intro Ready to try MindTap with a sample Biology chapter?
              a.btn.btn--large See Digital Sample
              a.btn.btn--hollow.btn--large Log in to MindTap
              br
              a.btn.btn--link No thanks, maybe later.
  script(src="//cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.js")
  script.
    $('.tours__list').slick({
      dots: true,
      infinite: false
    });
  script.
    var tour = {
      "title": "Mobile Optimized",
      "sections": [
        {
          "title": "Heather’s running late, and has a test.",
          "body": "Since the MindTap reader is optimized for her handheld device, she can squeeze every minute out of her short bus ride, and study along the way.",
          "img": "/images/tours/ph-1.jpg"
        },
        {
          "title": "Your quiz covers 200 pages. Heather has 20 minutes.",
          "body": "That’s why she used MindTap on her tablet last night to bookmark the things she needs to spend more time on. Now she can quickly study from a select collection of pages.",
          "img": "/images/tours/ph-1.jpg"
        },
        {
          "title": "Heather knows the bus isn’t a library. ",
          "body": "Today it will have to do, and with MindTap’s hands free text-to-speech feature and headphones, she can hold on tight and tune out the baby beside her at the same time.",
          "img": "/images/tours/ph-1.jpg"
        },
        {
          "title": "Heather’s lifestyle is fast paced and hectic.",
          "body": "But she’s still at the top of her class. The workflow & learning styles of students like heather are best suited to a single, easy-to-navigate platform with realtime access to help.",
          "img": "/images/tours/ph-1.jpg"
        }
      ]
    }

Styleguide 2.8.0

*/

.styleguide-element {
  .tours {
    position: relative;
    z-index: auto;
    &__carousel {
      transform: none;
      top: 0;
      left: 0;
    }
  }
}
.tours {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  background: white;
  overflow: auto;
  &__carousel {
    position: relative;
    width: 100%;
    @media #{$desktop-up} {
      height: 500px;
      transform: translate(-50%,-50%);
      top: 50%;
      left: 50%;
    }
  }
  &__list {
    list-style: none;
    /*
    position: absolute;
    margin: 0;
    padding: 0;
    left: 0;
    top: 0;*/
  }
  &__cta {
    padding: 100px 0;
    background: url(/images/tours/bg-cta.jpg) no-repeat center center;
  }
  &__title {
    font-size: 16px;
    @include color(grey);
    text-transform: uppercase;
    line-height: 1;
    margin: 30px 0 10px 0;
    display: inline-block;
    @media #{$desktop-up} {
      margin: 0 0 20px 0;
    }
  }
  &__description {
    margin-top: 20px;
    text-align: center;
    @media #{$desktop-up} {
      margin-top: 110px;
      padding-right: 100px;
      text-align: left;
    }
  }
  &__page {
    font-size: 16px;
    margin: 0;
    @include color(grey);
  }
  &__intro {
    font-size: 20px;
    @media #{$desktop-up} {
      font-size: 30px;
    }
  }
  &__text {
    font-size: 16px;
    line-height: 1.6em;
  }
  &__img-container {
    background-image: url(/images/tours/bg-spot1@2x.png);
    background-repeat: no-repeat;
    background-position: 0 center;
    background-size: contain;
    &-gif {
      padding: 90px 30px;
      @media #{$desktop-up} {
        padding: 90px 0;
      }
    }
  }
  .tours__list:nth-child(0n+2) .tours__img-container {
    background-image: url(/images/tours/bg-spot3@2x.png);
  }
  .tours__list:nth-child(0n+3) .tours__img-container {
    background-image: url(/images/tours/bg-spot2@2x.png);
  }
  
  &__img {
    max-width: 100%;
    height: auto;
    display: block;
  }
}

// slick overrides


.tours__carousel {
  
  button {
    cursor: pointer;    
    &.slick-next, &.slick-prev {
      appearance: none;
      background: transparent;
      border: none;
      position: absolute;
      top: 50%;
      color: rgba(0, 0, 0, 0);
      z-index: 9;
      font-size: 1px;
    }
    &.slick-next:before, &.slick-prev:before {
      z-index: 1000;
      font-size: 30px!important;
      line-height: 1;
      display: block;
      opacity: 1!important;
      @include color(grey);
      font-family: 'cengage-icons'!important;
    }
    &.slick-next.slick-disabled:before, &.slick-prev.slick-disabled:before {
      opacity: 0!important;
    }
    &.slick-prev {
      left: 0!important;
      @media #{$desktop-up} {
        left: 25px!important;
      }
    }
    
    &.slick-next {
      right: 0!important;
      @media #{$desktop-up} {
        right: 25px!important;
      }
    }
    &.slick-next:before {
      content: '\EA04'!important;
    }
    &.slick-prev:before {
      content: '\EA05'!important;
    }
  }
}


.slick-dots {
  bottom: 25px!important;
}
.slick-dots li {
  margin: 0!important;
}
ul.slick-dots li button {
  background-color: #D8D8D8!important;
  width: 9px;
  height: 9px;
}
ul.slick-dots li.slick-active button {
  background-color: $brand-primary!important;
}
