/*
Typography

Typography defines scale, size, weight and line-heights for all tinyMCE scenarios.

Markup:
<h1>H1 Header style</h1>
<h2>H2 Header style</h2>
<h3>H3 Header style</h3>
<h4>H4 Features</h4>
<p>Body Regular - <em>Lorem ipsum dolor sit</em> amet, <strong>consectetur adipiscing</strong> elit, sed do eiusmod tempor incididunt ut labore et <a href="#0">dolore magna aliqua</a>. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
<blockquote>
  <p>Coast Capital, we pride ourselves in living our mission to bringing our members  “simple financial help," and our promise to our employees, which is to change  the way employees feel about work, forever."</p>
  <span>Don Coulter</span>
  CEO, Coast Capital Savings
</blockquote>
<ul>
  <li>Unordered List Item</li>
  <li>Unordered List Item</li>
  <li>Unordered List Item</li>
  <li>Unordered List Item</li>
</ul>
<ol>
  <li>Ordered List Item</li>
  <li>Ordered List Item</li>
  <li>Ordered List Item</li>
  <li>Ordered List Item</li>
</ol>
<blockquote>Testing</blockquote>

Styleguide 1.2.0

*/
@import url("http://fonts.googleapis.com/css?family=Open+Sans:300,400,600,600i");

/*
@font-face {
	font-family: 'Gnuolane';
	src: url('fonts/gnuolane rg.eot');
	src: local('☺'),
	     url('fonts/gnuolane rg.woff') format('woff'),
	     url('fonts/gnuolane rg.ttf') format('truetype'),
	     url('fonts/gnuolane rg.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}
*/



/* Optimize type */
body {
  text-rendering: optimizeLegibility;
  font-family: 'Open Sans', helvetica, sans-serif;
  font-size: 12px;
  line-height: 1.5em;
  color: map-get($colors,black);
  @media #{$desktop-up} {
    font-size: 16px;
  }
}
.h1,h1,h2,h3 {
  font-family: 'Open Sans';
  font-weight: 600;
//  color: $brand-primary;
  -webkit-font-smoothing: antialiased;
}
.h1,
h1 {
  font-size: 30px;
  line-height: 1.3em;
  margin: 0 0 16px;
  font-weight: 300;
  @media #{$desktop-up} {
    font-size: 48px;
    line-height: 64px;
  }
}
h2 {
  font-size: 18px;
  line-height: 1.5em;
  margin: 0;
  @media #{$desktop-up} {
    font-size: 22px;
    line-height: 42px;
    margin: 10px 0;
  }
}
h3 {
  font-size: 16px;
  line-height: 26px;
  @media #{$desktop-up} {
    font-size: 18px;
    line-height: 32px;
  }
}

blockquote {
	@include type(13px, 21px);
	padding: 15px 30px 15px;
	@include backgroundColor(white);
	border-left: 2px solid map-get($colors, blue);
	font-family: ''Open Sans'';
	margin: 10px 0 40px;
	@include color(blue);

	p:first-of-type {
		@include type(16px, 24px);
	}
	strong {
  	display: inline-block;
  	margin-top: 10px;
	}
	strong,em {
 		@include color(black);
 		font-style: normal;
	}
}
