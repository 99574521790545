//TRANSITION
@mixin transition($attr:all,$type:ease,$timing:.2s) {
	transition: $attr $timing $type;
}




//TYPE
@mixin type($fontsize:12px,$lineheight:17px) {
	font-size: $fontsize;
	line-height: $lineheight;
}




//BOX SHADOW
@mixin boxshadow($hlength:0px,$vlength:0px,$blur:2px,$spread:1px,$color:rgba(0,0,0,0.13)) {
	box-shadow: $hlength $vlength $blur $spread $color;
}




//TEXT SHADOW
@mixin textshadow($hlength:0px,$vlength:2px,$blur:2px,$color:rgba(0,0,0,0.13)) {
	text-shadow: $hlength $vlength $blur $color;
}




//COLOR
@mixin color($color:black) {
	color: map-get($colors,$color);
}




//BACKGROUND COLOR
@mixin backgroundColor($color:black) {
	background-color: map-get($colors,$color);
}




// PAGE LAYOUT
@mixin maxwidth(){
	max-width: $contentWidth;
}


// Vertical Align Center
@mixin vertical-align($position: relative) {
  position: $position;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.vertical-align {
  @include vertical-align();
}

// placeholder colour
@mixin placeholder($color) {
	::-webkit-input-placeholder {
	   color: $color;
	}
	:-moz-placeholder { /* Firefox 18- */
	   color: $color;
	}
	::-moz-placeholder {  /* Firefox 19+ */
	   color: $color;
	}
	:-ms-input-placeholder {
	   color: $color;
	}
}

//BORDER TOP: Provides finer borders
@mixin borderTop($color:black,$opacity:33%) {
	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 1px;
		background-image: linear-gradient(to right, lighten(map-get($colors,$color),20) $opacity, rgba(255,255,255,0) 0%);
		background-position: bottom;
		background-size: 5px 1px;
		background-repeat: repeat-x;
	}
}




// Flexbox shorthand
@mixin flexbox() {
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
}




// Flex shorthand
@mixin flex($values: auto) {
	-webkit-box-flex: $values;
	-moz-box-flex:  $values;
	-webkit-flex:  $values;
	-ms-flex:  $values;
	flex:  $values;
}




// Smartbook colors
@mixin smartbookColors($color) {
	&--#{$color} {
		.radio {
			label:after {
				@include backgroundColor($color);
			}
		}
		.smartbook__intro {
			@include backgroundColor($color);
			@include color(white);
			.btn {
				&:hover {
					@include color($color);
				}
			}
		}
		.smartbook__tabs {
			span.active {
				@include backgroundColor($color);
			}
		}
		.smartbook__share {
			@include borderTop($color);
		}
		@media #{$desktop-up} {
			.js-inline-tweet:hover {
				.js-inline-tweet__text {
					background-color: darken(map-get($colors,$color),10);
					border-color: darken(map-get($colors,$color),10);
				}
			}
		}
	}
}

// Typographic Mixins
@mixin typeExtraSmall {
	@include type(11px,18px);
}
@mixin typeSmall {
	@include type(12px,17px);
	@media #{$desktop-up} {
		@include type(13px,22px);
	}
}
@mixin typeMedium {
	@include type(14px,24px);
}
@mixin typeLarge {
	@include type(14px,24px);
	@media #{$desktop-up} {
		@include type(21px,32px);
	}
}
@mixin h1 {
	@include color(blue);
	font-family: ''Open Sans'';
	font-weight: normal;
	@include type(24px,32px);
	margin: 0 0 16px;
	&.type--large {
		font-family:"universe";
	}
	@media #{$desktop-up} {
		@include type(48px,64px);
		margin: 0 0 30px;
	}
}
@mixin h2 {
	@include color(blue);
	font-family: ''Open Sans'';
	font-weight: normal;
	@include type(20px,32px);
	margin: 0 0 16px;
	&.type--large {
		font-family:"universe";
	}
	@media #{$desktop-up} {
		@include type(32px,42px);
		margin: 0 0 16px;
	}
}
@mixin h3 {
	@include color(blue);
	font-family: ''Open Sans'';
	font-weight: normal;
	@include type(18px,26px);
	&.type--large {
		font-family:"universe";
	}
	@media #{$desktop-up} {
		@include type(24px,32px);
	}
}
@mixin h4 {
	@include color(black);
	@include type(17px,20px);
	@include color(black);
	font-family: 'universebold';
	font-weight: normal;
}

@mixin icon {
  font-family: 'cengage-icons' !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;    
}


// CLEAR
@mixin clearfix {
	&:after,
	&:before {
		content: '';
		display: table;
	}
	&:after {
		clear: both;
	}
}