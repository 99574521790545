.pull-right {
  float: right;
}

.pull-left {
  float: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.hide-desktop {
  display: block;
  @media #{$desktop-up} {
    display: none;
  }
}

.a-link {
  cursor: pointer;
}

.hide-mobile {
  display: none;
  @media #{$desktop-up} {
    display: block;
  }
}
