/*
Bumpers

Bumpers section(default, with tooltips)

Markup:
.bumper
  h2 Plan your course now, with full access to MindTap Biology.
  p
    a.btn.btn--large(href="#0") Log In
  p Don’t have an account?&nbsp;&nbsp;&nbsp;
    a(href="#0") Sign me up

Styleguide 2.3.

*/


.bumper {
	@include color(white);
	@include backgroundColor(charcoal);
	position: relative;
	text-align: center;
	padding: 30px 30px 25px;
	overflow: hidden;
	line-height: normal;
	display: none;
	a:not([class]) {
	  @include color(offwhite);
	}
	h2 {
  	font-size: 30px;
	}
	.bumper__title {
  	font-size: 30px;
  	@include color(white);
	}
	.btn {
		margin: 20px 10px;
    position: relative;
	}
  &__link {
    text-decoration: underline;
  	@include color(white);
  }
	@media #{$desktop-up} {
  	display: block;
		padding: 60px 250px;
	}
}
