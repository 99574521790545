/*
Header

The main header

Markup:
.header
  .container
    .row
      .col6.header__logo
        a(href='//192.168.100.119:3000/')
          img(src="/images/nav/mindtap-logo.svg",width="170",height="45")
        span.header__subtext for biology
      .col6.header__nav
        ul
          li
            a.link Sign Up
          li
            a.btn Log In
    a#header__toggle.icon.icon--toggle

Styleguide 2.1.0

*/
.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  background-color: #fff;
  height: 80px;
  width: 100%;
  padding: 9px 10px;
  box-shadow: 0 2px 3px 0 rgba(0,0,0,0.3);

  @include color(grey);
  -webkit-font-smoothing: subpixel-antialiased;
  .link {
    color: $brand-primary;
    text-decoration: none;
    font-weight: 600;
    &:hover {
  		text-decoration: underline;
    }
  }
  &__logo {
    position: relative;
    top: 10px;
  }
  &__subtext {
    position: relative;
    bottom: 15px;
    margin-left: 8px;
    @include color(black);
  }
  &__nav {
    text-align: right;
    display: none;
    font-size: 14px;
    @media #{$desktop-up} {
      display: block;
    }
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      li {
        display: inline-block;
        margin: 5px;

      }
    }

  }
  &__toggle {
    position: absolute;
    right: 0;
    padding: 5px;
    top: 0;
    cursor: pointer;
    .icon--toggle {
      color: #FFF;
      &:before {
        content: "\EA13";
        font-size: 20px;
      }
    }
  }
}



