// Glyphicons font path
$icon-font-path:        "../fonts/";

// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors
$brand-primary:         #007db8  ;
$brand-secondary:       #90b82e  ;

// Breakpoints
$mobile-only: (max-width: 640px);
$tablet-up: (min-width: 768px);
$desktop-up: (min-width: 1024px);

// General Vars
$border-radius: 8px;


// Animation
$easeout01: cubic-bezier(.5,.5,0,1);
$easein01: cubic-bezier(.77,0,.175,1);

// Key frames
@-webkit-keyframes circleRotationA {
    0%   {transform: rotateZ(0); transform-origin: 55% 55%;}
    100% {transform: rotateZ(360deg); transform-origin: 55% 55%;}
}
@keyframes circleRotationA {
		0%   {transform: rotateZ(0); transform-origin: 55% 55%;}
		100% {transform: rotateZ(360deg); transform-origin: 55% 55%;}
}

@-webkit-keyframes circleRotationB {
    0%   {transform: rotateZ(0); transform-origin: 45% 45%;}
    100% {transform: rotateZ(-360deg); transform-origin: 45% 45%;}
}
@keyframes circleRotationB {
		0%   {transform: rotateZ(0); transform-origin: 45% 45%;}
		100% {transform: rotateZ(-360deg); transform-origin: 45% 45%;}
}

@keyframes headlineDefault {
    0%   {transform: translateY(45px); opacity: 0;}
    100% {transform: translateY(0); opacity: 1;}
}

@keyframes headlineMobile {
    0%   {transform: translateY(20px); opacity: 0;}
    100% {transform: translateY(0); opacity: 1;}
}

@keyframes searchformSlideIn {
    0%   {transform: translateX(15px); opacity: 0;}
    100% {transform: translateX(0); opacity: 1;}
}

@keyframes slideUp {
	0% { transform: translateY(100%); }
	100% { transform: translateY(0%); }
}
@keyframes slideDown {
	0% { transform: translateY(-100%); }
	100% { transform: translateY(0%); }
}
@keyframes fadeIn {
	0% { opacity: 0; }
	100% { opacity: 1; }
}

/*
Colours

Colours can be used on foreground elements (like type) or background elements by prefixing a color with bg-- modifier.

Markup:
<div class="{$modifiers} bg--{$modifiers}">&nbsp;</div>

.black      - \#000
.charcoal   - \#3f3f3f
.blue       - \#0067C6
.green      - \#54B818
.grey       - \#999
.orange     - \#FC7B0A
.purple     - \#733A98
.red        - \#D0021B
.white      - \#FFF
.yellow     - \#F5D101

Styleguide 1.2.

*/



$colors: (
black: #000000 ,
charcoal: #3f3f3f  ,
blue: #007db8  ,
green: #90b82e  ,
grey: #7a7a7a ,
offwhite: #F7F7F7  ,
orange: #FC7B0A  ,
purple: #733A98  ,
red: #fa6600  ,
white: #FFFFFF ,
yellow: #F5D101  ,
facebook: #2C549A  ,
twitter: #219FE1  ,
instagram: #000,
google: #dd4b39  ,
linkedin: #156BA1  ,
youtube: #BD1918  ,
);
// $instagram: linear-gradient(to right, rgba(254,214,122,1) 0%, rgba(245,97,56,1) 25%, rgba(223,42,108,1) 50%, rgba(172,51,178,1) 75%, rgba(73,103,213,1) 100%);

@each $key, $color in $colors {
  .#{$key} {
  color: $color;
  }
  .bg--#{$key} {
  background-color: $color;
  }
}
