.arrow-nav {
  list-style: none;
  margin: 0;
  padding: 0;
  a {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    cursor: pointer;
  }
  &__back, &__next {
    position: absolute;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
    background-color: #DFDFDF;
    width: 50px;
    height: 80px;
    border-radius: 3px;
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
    cursor: pointer;
    &.disabled {
      opacity: 0;
      pointer-events: none;
    }
  }
  &__back {
    left: 25px;
    top: 400px;
  }
  &__next {
    right: 25px;
    top: 400px;
  }

  &__back-icon:after, &__next-icon:after {
    color: #7A7A7A;
    display: block;
    text-align: center;
    width: 100%;
    height: 100%;
    font-family: 'cengage-icons' !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 80px;
    vertical-align: middle;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  &__back-icon:after {
    content: "\EA05";
  }
  &__next-icon:after {
    content: "\EA04";
  }

}

.link-list {
  ul.slick-dots li.slick-active button {
    background-color: white!important;
    opacity: 1;
  }
  ul.slick-dots li button {
    opacity: 0.5;
  }
  .slick-disabled {
    opacity: 0.3;
  }
  button.slick {
    a {
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      cursor: pointer;
    }
    &-prev, &-next {
      cursor: pointer;
      display: block;
      appearance: none;
      outline: none;
      border: none;
      padding: 0;
      position: absolute;
      box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
      background-color: #DFDFDF;
      width: 30px;
      height: 50px;
      border-radius: 3px;
      top: calc(50% - 40px);
      color: rgba(0,0,0,0);
      cursor: pointer;
    }
    &-prev {
      left: -15px;
      z-index: 2;
    }
    &-next {
      right: -15px;
      padding-left: 2px;
    }

    &-prev:after, &-next:after {
      position: absolute;
      top: 0;
      color: #7A7A7A;
      display: block;
      text-align: center;
      width: 100%;
      height: 100%;
      font-size: 20px;
      font-family: 'cengage-icons' !important;
      font-style: normal !important;
      font-weight: normal !important;
      font-variant: normal !important;
      text-transform: none !important;
      speak: none;
      line-height: 50px;
      vertical-align: middle;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    &-prev:after {
      content: "\EA05";
    }
    &-next:after {
      content: "\EA04";
    }

  }

}
