/**
  // DO NOT EDIT DIRECTLY!
  //Generated by gulpfile.js/tasks/iconFont/index.js
  //from undefined
*/

@charset "UTF-8";

@font-face {
  font-family: 'cengage-icons';
  src: url('/fonts/cengage-icons.eot');
  src:
    url('/fonts/cengage-icons.eot?#iefix') format('embedded-opentype'),
    url('/fonts/cengage-icons.woff') format('woff'),
    url('/fonts/cengage-icons.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.icon:before {
  font-family: 'cengage-icons' !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon--facebook {
  &:before {
    content: "\EA01";
  }  
}

.icon--instagram {
  &:before {
    content: "\EA02";
  }  
}

.icon--twitter {
  &:before {
    content: "\EA03";
  }  
}

.icon--chevron {
  &:before {
    content: "\EA04";
  }  
}

.icon--back {
  &:before {
    content: "\EA05";
  }  
}

.icon--book {
  &:before {
    content: "\EA06";
  }  
}

.icon--grad-hat {
  &:before {
    content: "\EA07";
  }  
}

.icon--video {
  &:before {
    content: "\EA08";
  }  
}

.icon--check {
  &:before {
    content: "\EA09";
  }  
}

.icon--close {
  &:before {
    content: "\EA10";
  }  
}

.icon--play {
  &:before {
    content: "\EA11";
  }  
}

.icon--down {
  &:before {
    content: "\EA12";
  }  
}



/*
Icons

icons are generated by placing SVGs inside the `/icons/` folder with the PUA code then a dash, then the icon name.

Markup:
<i class="icon {$modifiers}" aria-hidden="true"></i>

.icon--facebook - facebook
.icon--instagram - instagram
.icon--twitter - twitter
.icon--chevron - chevron
.icon--back - back
.icon--book - book
.icon--grad-hat - grad-hat
.icon--video - video
.icon--check - check
.icon--close - close
.icon--play - play
.icon--down - down
.icon--before - Add spacing after an icon (sitting before something in the markup)
.icon--after - Add spacing before an icon (sitting after something in the markup)

Styleguide 1.4.0

*/