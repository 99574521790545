@charset "UTF-8";
@import url("http://fonts.googleapis.com/css?family=Open+Sans:300,400,600,600i");
@-webkit-keyframes circleRotationA {
  0% {
    transform: rotateZ(0);
    transform-origin: 55% 55%; }
  100% {
    transform: rotateZ(360deg);
    transform-origin: 55% 55%; } }

@keyframes circleRotationA {
  0% {
    transform: rotateZ(0);
    transform-origin: 55% 55%; }
  100% {
    transform: rotateZ(360deg);
    transform-origin: 55% 55%; } }

@-webkit-keyframes circleRotationB {
  0% {
    transform: rotateZ(0);
    transform-origin: 45% 45%; }
  100% {
    transform: rotateZ(-360deg);
    transform-origin: 45% 45%; } }

@keyframes circleRotationB {
  0% {
    transform: rotateZ(0);
    transform-origin: 45% 45%; }
  100% {
    transform: rotateZ(-360deg);
    transform-origin: 45% 45%; } }

@keyframes headlineDefault {
  0% {
    transform: translateY(45px);
    opacity: 0; }
  100% {
    transform: translateY(0);
    opacity: 1; } }

@keyframes headlineMobile {
  0% {
    transform: translateY(20px);
    opacity: 0; }
  100% {
    transform: translateY(0);
    opacity: 1; } }

@keyframes searchformSlideIn {
  0% {
    transform: translateX(15px);
    opacity: 0; }
  100% {
    transform: translateX(0);
    opacity: 1; } }

@keyframes slideUp {
  0% {
    transform: translateY(100%); }
  100% {
    transform: translateY(0%); } }

@keyframes slideDown {
  0% {
    transform: translateY(-100%); }
  100% {
    transform: translateY(0%); } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

/*
Colours

Colours can be used on foreground elements (like type) or background elements by prefixing a color with bg-- modifier.

Markup:
<div class="{$modifiers} bg--{$modifiers}">&nbsp;</div>

.black      - \#000
.charcoal   - \#3f3f3f
.blue       - \#0067C6
.green      - \#54B818
.grey       - \#999
.orange     - \#FC7B0A
.purple     - \#733A98
.red        - \#D0021B
.white      - \#FFF
.yellow     - \#F5D101

Styleguide 1.2.

*/
.black {
  color: #000000; }

.bg--black {
  background-color: #000000; }

.charcoal {
  color: #3f3f3f; }

.bg--charcoal {
  background-color: #3f3f3f; }

.blue {
  color: #007db8; }

.bg--blue {
  background-color: #007db8; }

.green {
  color: #90b82e; }

.bg--green {
  background-color: #90b82e; }

.grey {
  color: #7a7a7a; }

.bg--grey {
  background-color: #7a7a7a; }

.offwhite {
  color: #F7F7F7; }

.bg--offwhite {
  background-color: #F7F7F7; }

.orange {
  color: #FC7B0A; }

.bg--orange {
  background-color: #FC7B0A; }

.purple {
  color: #733A98; }

.bg--purple {
  background-color: #733A98; }

.red {
  color: #fa6600; }

.bg--red {
  background-color: #fa6600; }

.white {
  color: #FFFFFF; }

.bg--white {
  background-color: #FFFFFF; }

.yellow {
  color: #F5D101; }

.bg--yellow {
  background-color: #F5D101; }

.facebook {
  color: #2C549A; }

.bg--facebook {
  background-color: #2C549A; }

.twitter {
  color: #219FE1; }

.bg--twitter {
  background-color: #219FE1; }

.instagram {
  color: #000; }

.bg--instagram {
  background-color: #000; }

.google {
  color: #dd4b39; }

.bg--google {
  background-color: #dd4b39; }

.linkedin {
  color: #156BA1; }

.bg--linkedin {
  background-color: #156BA1; }

.youtube {
  color: #BD1918; }

.bg--youtube {
  background-color: #BD1918; }

* {
  box-sizing: border-box; }

body {
  margin: 0;
  padding: 80px 0 0 0;
  font-family: sans-serif; }

img {
  max-width: 100%;
  height: auto;
  border: none; }

@media (max-width: 1023px) {
  .hidden-mobile {
    display: none; } }

.video-16x9 {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  height: 0; }
  .video-16x9 iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

/**
  // DO NOT EDIT DIRECTLY!
  //Generated by gulpfile.js/tasks/iconFont/index.js
  //from undefined
*/
@font-face {
  font-family: 'cengage-icons';
  src: url("/fonts/cengage-icons.eot");
  src: url("/fonts/cengage-icons.eot?#iefix") format("embedded-opentype"), url("/fonts/cengage-icons.woff") format("woff"), url("/fonts/cengage-icons.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

.icon:before {
  font-family: 'cengage-icons' !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon--facebook:before {
  content: "\EA01"; }

.icon--instagram:before {
  content: "\EA02"; }

.icon--twitter:before {
  content: "\EA03"; }

.icon--chevron:before {
  content: "\EA04"; }

.icon--back:before {
  content: "\EA05"; }

.icon--book:before {
  content: "\EA06"; }

.icon--grad-hat:before {
  content: "\EA07"; }

.icon--video:before {
  content: "\EA08"; }

.icon--check:before {
  content: "\EA09"; }

.icon--close:before {
  content: "\EA10"; }

.icon--play:before {
  content: "\EA11"; }

.icon--down:before {
  content: "\EA12"; }

/*
Icons

icons are generated by placing SVGs inside the `/icons/` folder with the PUA code then a dash, then the icon name.

Markup:
<i class="icon {$modifiers}" aria-hidden="true"></i>

.icon--facebook - facebook
.icon--instagram - instagram
.icon--twitter - twitter
.icon--chevron - chevron
.icon--back - back
.icon--book - book
.icon--grad-hat - grad-hat
.icon--video - video
.icon--check - check
.icon--close - close
.icon--play - play
.icon--down - down
.icon--before - Add spacing after an icon (sitting before something in the markup)
.icon--after - Add spacing before an icon (sitting after something in the markup)

Styleguide 1.4.0

*/
.vertical-align {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%); }

/*
Typography

Typography defines scale, size, weight and line-heights for all tinyMCE scenarios.

Markup:
<h1>H1 Header style</h1>
<h2>H2 Header style</h2>
<h3>H3 Header style</h3>
<h4>H4 Features</h4>
<p>Body Regular - <em>Lorem ipsum dolor sit</em> amet, <strong>consectetur adipiscing</strong> elit, sed do eiusmod tempor incididunt ut labore et <a href="#0">dolore magna aliqua</a>. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
<blockquote>
  <p>Coast Capital, we pride ourselves in living our mission to bringing our members  “simple financial help," and our promise to our employees, which is to change  the way employees feel about work, forever."</p>
  <span>Don Coulter</span>
  CEO, Coast Capital Savings
</blockquote>
<ul>
  <li>Unordered List Item</li>
  <li>Unordered List Item</li>
  <li>Unordered List Item</li>
  <li>Unordered List Item</li>
</ul>
<ol>
  <li>Ordered List Item</li>
  <li>Ordered List Item</li>
  <li>Ordered List Item</li>
  <li>Ordered List Item</li>
</ol>
<blockquote>Testing</blockquote>

Styleguide 1.2.0

*/
/*
@font-face {
	font-family: 'Gnuolane';
	src: url('fonts/gnuolane rg.eot');
	src: local('☺'),
	     url('fonts/gnuolane rg.woff') format('woff'),
	     url('fonts/gnuolane rg.ttf') format('truetype'),
	     url('fonts/gnuolane rg.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}
*/
/* Optimize type */
body {
  text-rendering: optimizeLegibility;
  font-family: 'Open Sans', helvetica, sans-serif;
  font-size: 12px;
  line-height: 1.5em;
  color: #000000; }
  @media (min-width: 1024px) {
    body {
      font-size: 16px; } }

.h1, h1, h2, h3 {
  font-family: 'Open Sans';
  font-weight: 600;
  -webkit-font-smoothing: antialiased; }

.h1,
h1 {
  font-size: 30px;
  line-height: 1.3em;
  margin: 0 0 16px;
  font-weight: 300; }
  @media (min-width: 1024px) {
    .h1,
    h1 {
      font-size: 48px;
      line-height: 64px; } }

h2 {
  font-size: 18px;
  line-height: 1.5em;
  margin: 0; }
  @media (min-width: 1024px) {
    h2 {
      font-size: 22px;
      line-height: 42px;
      margin: 10px 0; } }

h3 {
  font-size: 16px;
  line-height: 26px; }
  @media (min-width: 1024px) {
    h3 {
      font-size: 18px;
      line-height: 32px; } }

blockquote {
  font-size: 13px;
  line-height: 21px;
  padding: 15px 30px 15px;
  background-color: #FFFFFF;
  border-left: 2px solid #007db8;
  font-family: "" Open Sans "";
  margin: 10px 0 40px;
  color: #007db8; }
  blockquote p:first-of-type {
    font-size: 16px;
    line-height: 24px; }
  blockquote strong {
    display: inline-block;
    margin-top: 10px; }
  blockquote strong, blockquote em {
    color: #000000;
    font-style: normal; }

.container {
  position: relative;
  max-width: 80em;
  margin-left: auto;
  margin-right: auto; }
  .container:after {
    content: " ";
    display: block;
    clear: both; }

.row:before, .row:after {
  display: table;
  content: ''; }

.row:after {
  clear: both; }

.col2 {
  padding: 0 20px; }
  @media (min-width: 1024px) {
    .col2 {
      padding: 0;
      width: 14.52380952%;
      float: left;
      margin-left: 1.07142857%;
      margin-right: 1.07142857%; } }

.col3 {
  padding: 0 20px; }
  @media (min-width: 1024px) {
    .col3 {
      padding: 0;
      width: 22.85714286%;
      float: left;
      margin-left: 1.07142857%;
      margin-right: 1.07142857%; } }

.col4 {
  padding: 0 20px; }
  @media (min-width: 1024px) {
    .col4 {
      padding: 0;
      width: 31.19047619%;
      float: left;
      margin-left: 1.07142857%;
      margin-right: 1.07142857%; } }

.col5 {
  padding: 0 20px; }
  @media (min-width: 1024px) {
    .col5 {
      padding: 0;
      width: 39.52380952%;
      float: left;
      margin-left: 1.07142857%;
      margin-right: 1.07142857%; } }

.col6 {
  padding: 0 20px; }
  @media (min-width: 1024px) {
    .col6 {
      padding: 0;
      width: 47.85714286%;
      float: left;
      margin-left: 1.07142857%;
      margin-right: 1.07142857%; } }

.col7 {
  padding: 0 20px; }
  @media (min-width: 1024px) {
    .col7 {
      padding: 0;
      width: 56.19047619%;
      float: left;
      margin-left: 1.07142857%;
      margin-right: 1.07142857%; } }

.col8 {
  padding: 0 20px; }
  @media (min-width: 1024px) {
    .col8 {
      padding: 0;
      width: 64.52380952%;
      float: left;
      margin-left: 1.07142857%;
      margin-right: 1.07142857%; } }

.col9 {
  padding: 0 20px; }
  @media (min-width: 1024px) {
    .col9 {
      padding: 0;
      width: 72.85714286%;
      float: left;
      margin-left: 1.07142857%;
      margin-right: 1.07142857%; } }

.col10 {
  padding: 0 20px; }
  @media (min-width: 1024px) {
    .col10 {
      padding: 0;
      width: 81.19047619%;
      float: left;
      margin-left: 1.07142857%;
      margin-right: 1.07142857%; } }

.col12 {
  padding: 0 20px; }
  @media (min-width: 1024px) {
    .col12 {
      padding: 0;
      width: 97.85714286%;
      float: left;
      margin-left: 1.07142857%;
      margin-right: 1.07142857%; } }

@media (min-width: 1024px) {
  .col1-offset {
    margin-left: 9.4047619%; } }

@media (min-width: 1024px) {
  .col2-offset {
    margin-left: 17.73809524%; } }

@media (min-width: 1024px) {
  .col3-offset {
    margin-left: 26.07142857%; } }

@media (min-width: 1024px) {
  .col4-offset {
    margin-left: 34.4047619%; } }

@media (min-width: 1024px) {
  .col5-offset {
    margin-left: 42.73809524%; } }

@media (min-width: 1024px) {
  .col6-offset {
    margin-left: 51.07142857%; } }

@media (min-width: 1024px) {
  .col8-offset {
    margin-left: 67.73809524%; } }

@media (min-width: 1024px) {
  .col12-offset {
    margin-left: 101.07142857%; } }

.wrap {
  padding-bottom: 30px; }
  @media (min-width: 1024px) {
    .wrap {
      padding-bottom: 120px; } }

.wrap-outer {
  position: relative;
  z-index: 3;
  background-color: #FFF;
  box-shadow: 0 10px 20px -10px #C2C4C6; }
  @media (min-width: 1024px) {
    .wrap-outer {
      box-shadow: none; } }

.row-no-gutters {
  margin-left: 1.07142857%;
  margin-right: 1.07142857%; }

.row-no-gutters .col2, .row-no-margins .col2 {
  width: 16.66666667%;
  float: left;
  margin-left: 0;
  margin-right: 0; }

.row-no-gutters .col3, .row-no-margins .col3 {
  width: 25%;
  float: left;
  margin-left: 0;
  margin-right: 0; }

.row-no-gutters .col4, .row-no-margins .col4 {
  width: 33.33333333%;
  float: left;
  margin-left: 0;
  margin-right: 0; }

.row-no-gutters .col5, .row-no-margins .col5 {
  width: 41.66666667%;
  float: left;
  margin-left: 0;
  margin-right: 0; }

.row-no-gutters .col6, .row-no-margins .col6 {
  width: 50%;
  float: left;
  margin-left: 0;
  margin-right: 0; }

.row-no-gutters .col7, .row-no-margins .col7 {
  width: 58.33333333%;
  float: left;
  margin-left: 0;
  margin-right: 0; }

.row-no-gutters .col8, .row-no-margins .col8 {
  width: 66.66666667%;
  float: left;
  margin-left: 0;
  margin-right: 0; }

.row-no-gutters .col9, .row-no-margins .col9 {
  width: 75%;
  float: left;
  margin-left: 0;
  margin-right: 0; }

.row-no-gutters .col10, .row-no-margins .col10 {
  width: 83.33333333%;
  float: left;
  margin-left: 0;
  margin-right: 0; }

.row-no-gutters .col12, .row-no-margins .col12 {
  width: 100%;
  float: left;
  margin-left: 0;
  margin-right: 0; }

.arrow-nav {
  list-style: none;
  margin: 0;
  padding: 0; }
  .arrow-nav a {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    cursor: pointer; }
  .arrow-nav__back, .arrow-nav__next {
    position: absolute;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    background-color: #DFDFDF;
    width: 50px;
    height: 80px;
    border-radius: 3px;
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
    cursor: pointer; }
    .arrow-nav__back.disabled, .arrow-nav__next.disabled {
      opacity: 0;
      pointer-events: none; }
  .arrow-nav__back {
    left: 25px;
    top: 400px; }
  .arrow-nav__next {
    right: 25px;
    top: 400px; }
  .arrow-nav__back-icon:after, .arrow-nav__next-icon:after {
    color: #7A7A7A;
    display: block;
    text-align: center;
    width: 100%;
    height: 100%;
    font-family: 'cengage-icons' !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 80px;
    vertical-align: middle;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }
  .arrow-nav__back-icon:after {
    content: "\EA05"; }
  .arrow-nav__next-icon:after {
    content: "\EA04"; }

.link-list ul.slick-dots li.slick-active button {
  background-color: white !important;
  opacity: 1; }

.link-list ul.slick-dots li button {
  opacity: 0.5; }

.link-list .slick-disabled {
  opacity: 0.3; }

.link-list button.slick a {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  cursor: pointer; }

.link-list button.slick-prev, .link-list button.slick-next {
  cursor: pointer;
  display: block;
  appearance: none;
  outline: none;
  border: none;
  padding: 0;
  position: absolute;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  background-color: #DFDFDF;
  width: 30px;
  height: 50px;
  border-radius: 3px;
  top: calc(50% - 40px);
  color: transparent;
  cursor: pointer; }

.link-list button.slick-prev {
  left: -15px;
  z-index: 2; }

.link-list button.slick-next {
  right: -15px;
  padding-left: 2px; }

.link-list button.slick-prev:after, .link-list button.slick-next:after {
  position: absolute;
  top: 0;
  color: #7A7A7A;
  display: block;
  text-align: center;
  width: 100%;
  height: 100%;
  font-size: 20px;
  font-family: 'cengage-icons' !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 50px;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.link-list button.slick-prev:after {
  content: "\EA05"; }

.link-list button.slick-next:after {
  content: "\EA04"; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0; }

.slick-list:focus {
  outline: none; }

.slick-list.dragging {
  cursor: pointer;
  cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block; }

.slick-track:before,
.slick-track:after {
  display: table;
  content: ''; }

.slick-track:after {
  clear: both; }

.slick-loading .slick-track {
  visibility: hidden; }

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px; }

[dir='rtl'] .slick-slide {
  float: right; }

.slick-slide img {
  display: block; }

.slick-slide.slick-loading img {
  display: none; }

.slick-slide.dragging img {
  pointer-events: none; }

.slick-initialized .slick-slide {
  display: block; }

.slick-loading .slick-slide {
  visibility: hidden; }

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

ul.slick-dots {
  text-align: center;
  padding: 0;
  margin: 30px 0 0; }
  ul.slick-dots li {
    display: inline-block; }
    ul.slick-dots li button {
      background-color: #7a7a7a;
      border: none;
      border-radius: 50%;
      width: 13px;
      height: 13px;
      overflow: hidden;
      text-indent: -5000px;
      margin: 5px;
      padding: 0; }
    ul.slick-dots li.slick-active button {
      background-color: #007db8; }

.pull-right {
  float: right; }

.pull-left {
  float: left; }

.text-center {
  text-align: center; }

.text-right {
  text-align: right; }

.hide-desktop {
  display: block; }
  @media (min-width: 1024px) {
    .hide-desktop {
      display: none; } }

.a-link {
  cursor: pointer; }

.hide-mobile {
  display: none; }
  @media (min-width: 1024px) {
    .hide-mobile {
      display: block; } }

/*
Header

The main header

Markup:
.header
  .container
    .row
      .col6.header__logo
        a(href='//192.168.100.119:3000/')
          img(src="/images/nav/mindtap-logo.svg",width="170",height="45")
        span.header__subtext for biology
      .col6.header__nav
        ul
          li
            a.link Sign Up
          li
            a.btn Log In
    a#header__toggle.icon.icon--toggle

Styleguide 2.1.0

*/
.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  background-color: #fff;
  height: 80px;
  width: 100%;
  padding: 9px 10px;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.3);
  color: #7a7a7a;
  -webkit-font-smoothing: subpixel-antialiased; }
  .header .link {
    color: #007db8;
    text-decoration: none;
    font-weight: 600; }
    .header .link:hover {
      text-decoration: underline; }
  .header__logo {
    position: relative;
    top: 10px; }
  .header__subtext {
    position: relative;
    bottom: 15px;
    margin-left: 8px;
    color: #000000; }
  .header__nav {
    text-align: right;
    display: none;
    font-size: 14px; }
    @media (min-width: 1024px) {
      .header__nav {
        display: block; } }
    .header__nav ul {
      list-style: none;
      padding: 0;
      margin: 0; }
      .header__nav ul li {
        display: inline-block;
        margin: 5px; }
  .header__toggle {
    position: absolute;
    right: 0;
    padding: 5px;
    top: 0;
    cursor: pointer; }
    .header__toggle .icon--toggle {
      color: #FFF; }
      .header__toggle .icon--toggle:before {
        content: "\EA13";
        font-size: 20px; }

/*
Landing Hero

The landing page side-by-side hero

Markup:
.landing
  .landing--hero
    .landing__content
      .row
        .col6.landing__left
          h2 MindTap for Biology, Effective Course Materials, All in One Place
          p Interact with a sample chapter from Russell's Biology the Dynamic Science 4th edition in the MindTap Reacher, and learn more about the text. Try your hand at some of the activities and assessments that are included in MindTap.
          .landing__buttons
            a.btn.btn--large.btn--orange(href="/sample/") Try MindTap for Biology
            a.btn.btn--large.btn--more(href="#tours") Explore MindTap's Features
              span.icon.icon--down
        .col6.landing__right
          a.google-play(href="#")
            img(src="/images/landing/google-play.png",width="143",height="46")
          a.app-store(href="#")
            img(src="/images/landing/app-store.png",width="143",height="46")
          .landing__layout
            img.layout__screen(src="/images/landing/screen.jpg",width="438",height="264")
            img.layout__cover(src="/images/isbn_resources/9781305389892.jpg",width="192",height="241")
            .layout__meta
              p Biology: The Dynamic Science 4th Edition
              small Peter J. Russell | Paul E. Hertz
  .landing--tours
    .landing__content
      h3
        a(name="tours") Explore MindTap's Features
      .link-list
        a(href="#").link-list__item
          img(src="http://placehold.it/200x200",width="200",height="200")
          small Personalize The Learning Path
          p Engage your students your way
        a(href="#").link-list__item
          img(src="http://placehold.it/200x200",width="200",height="200")
          small Real-time Analytics
          p Give students the support and confidence to succeed
        a(href="#").link-list__item
          img(src="http://placehold.it/200x200",width="200",height="200")
          small Mobile And Convenient
          p Put all the tools they'll need to succeed in one place
        a(href="#").link-list__item
          img(src="http://placehold.it/200x200",width="200",height="200")
          small Rich Activities To Apply Concepts
          p Helping students master conceptual understanding
        a(href="#").link-list__item
          img(src="http://placehold.it/200x200",width="200",height="200")
          small Personalizied Services
          p Service and support every step of the way
        a(href="#").link-list__item
          img(src="http://placehold.it/200x200",width="200",height="200")
          small Think Like A Biologist
          p Emphasize and apply the process of science.

Styleguide 2.4.

*/
.btn.btn--more {
  padding-right: 20px; }
  .btn.btn--more span {
    margin-left: 10px;
    font-size: 14px; }

.device-iOS .landing__buttons .btn, .device-android .landing__buttons .btn {
  display: none; }

.device-iOS .show-mobile, .device-android .show-mobile {
  display: block; }

.device-iOS .show-non-mobile, .device-android .show-non-mobile {
  display: none; }

.device-iOS .landing .app-store {
  display: block;
  text-align: center;
  margin: auto; }

.device-android .landing .google-play {
  display: block;
  text-align: center;
  margin: auto; }

.landing {
  background-color: #0070b4;
  background-image: linear-gradient(to bottom, #0070b4, #023158);
  overflow: hidden; }
  @media (min-width: 1024px) {
    .landing {
      padding-top: 70px; } }
  .landing .google-play, .landing .app-store {
    display: none; }
    .landing .google-play img, .landing .app-store img {
      display: inline-block; }
  .landing__buttons {
    display: none; }
    @media (min-width: 1024px) {
      .landing__buttons {
        display: block; } }
    .landing__buttons a {
      margin: 5px 0;
      display: block; }
    @media screen and (min-width: 1000px) {
      .landing__buttons {
        text-align: center; }
        .landing__buttons a {
          display: block;
          width: 400px; } }
    @media screen and (min-width: 1295px) {
      .landing__buttons {
        display: flex; }
        .landing__buttons a {
          margin: 5px; }
          .landing__buttons a:first-child {
            margin-left: 0; } }
  .landing__left {
    text-align: center; }
    .landing__left h1, .landing__left h2 {
      font-weight: 300; }
    @media screen and (min-width: 1000px) {
      .landing__left {
        text-align: left; } }
  .landing__right {
    text-align: center; }
    @media screen and (min-width: 1000px) {
      .landing__right {
        text-align: right; } }
  .landing .show-mobile {
    display: none; }
  .landing .show-non-mobile {
    display: block; }
  .landing__img-desktop {
    margin: auto; }
  .landing__img-mobile {
    display: block;
    margin: auto; }
    @media (min-width: 1024px) {
      .landing__img-mobile {
        display: none; } }
  .landing--hero {
    padding: 30px 0 3px 0;
    overflow: hidden;
    font-weight: 300; }
    @media (min-width: 1024px) {
      .landing--hero {
        padding: 0 0 130px 0;
        overflow: visible; } }
    .landing--hero h1, .landing--hero h2, .landing--hero p {
      color: #fff; }
    .landing--hero h1 {
      font-size: 28px; }
    .landing--hero h2 {
      font-size: 18px; }
    .landing--hero h1, .landing--hero h2 {
      line-height: 1.333em;
      margin: 0; }
    @media (min-width: 1024px) {
      .landing--hero h1, .landing--hero h2 {
        font-size: 46px; } }
    .landing--hero p {
      margin: 1em 0;
      font-size: 16px;
      line-height: 1.6em; }
  .landing .or-explore {
    color: #fff; }
    .landing .or-explore a {
      text-decoration: underline; }
  .landing--tours h3 {
    font-size: 22px;
    color: #fff;
    text-align: center; }
    @media (min-width: 1024px) {
      .landing--tours h3 {
        font-size: 30px; } }
  .landing__content {
    box-sizing: border-box;
    padding: 30px 20px; }
    @media (min-width: 1024px) {
      .landing__content {
        padding: 50px 20px; } }

.landing__layout {
  position: relative;
  margin: 30px 0;
  display: inline-block;
  text-align: left; }
  @media (min-width: 1024px) {
    .landing__layout {
      padding-left: 50px;
      margin: 0 30px 0 0; } }
  .landing__layout .layout__screen {
    box-shadow: 7px 15px 16px rgba(0, 0, 0, 0.5);
    position: absolute;
    max-width: none;
    left: -10px;
    border-radius: 2px; }
    @media (min-width: 1024px) {
      .landing__layout .layout__screen {
        left: auto;
        right: -20px;
        max-width: 100%;
        position: relative;
        margin-bottom: 60px; } }
  .landing__layout .layout__cover {
    box-shadow: 7px 15px 16px rgba(0, 0, 0, 0.24);
    position: relative;
    margin-top: 60px;
    left: -45px; }
    @media (min-width: 1024px) {
      .landing__layout .layout__cover {
        margin-top: 0;
        position: absolute;
        bottom: 0;
        left: 0; } }
  .landing__layout .layout__meta {
    text-align: center;
    margin-top: 20px; }
    @media (min-width: 1024px) {
      .landing__layout .layout__meta {
        margin-top: auto;
        text-align: left;
        position: absolute;
        left: 210px;
        top: 280px; } }
    .landing__layout .layout__meta p {
      font-size: 14px;
      margin: 0; }
    .landing__layout .layout__meta small {
      font-size: 12px;
      color: #fff; }

.landing-dicipline__layout {
  margin: 50px auto 0 auto;
  position: relative;
  text-align: center;
  padding: 100px 0;
  height: 273px;
  width: 360px; }
  .landing-dicipline__layout img {
    max-width: none; }
  @media (min-width: 1024px) {
    .landing-dicipline__layout {
      text-align: left;
      padding: 0 0 0 50px;
      margin: 0 30px 0 0;
      height: auto;
      width: auto; } }
  .landing-dicipline__layout .layout__lookinside {
    position: absolute;
    top: -15px;
    left: -115px; }
  .landing-dicipline__layout .layout__screen {
    position: absolute;
    max-width: none;
    left: 50%;
    width: 460px;
    transform: translateX(-50%);
    right: 0;
    top: 0; }
    @media (min-width: 1024px) {
      .landing-dicipline__layout .layout__screen {
        right: 20px;
        left: 0;
        width: auto;
        transform: none;
        position: absolute;
        margin-bottom: 60px; } }
  .landing-dicipline__layout .layout__cover {
    box-shadow: 7px 15px 16px rgba(0, 0, 0, 0.24);
    position: absolute;
    display: block;
    left: 30px;
    width: 80.5px;
    height: 101px; }
    @media (min-width: 1024px) {
      .landing-dicipline__layout .layout__cover {
        top: 131px;
        left: 105px;
        width: 161px;
        height: 202px;
        box-shadow: 7px 15px 16px 0 rgba(0, 0, 0, 0.19); } }
  .landing-dicipline__layout .layout__meta {
    text-align: left;
    margin-top: 0px;
    color: #3F3F3F;
    left: 140px;
    padding-right: 30px;
    position: absolute; }
    .landing-dicipline__layout .layout__meta small {
      font-size: 12px; }
    .landing-dicipline__layout .layout__meta .layout__authors {
      font-size: 12px; }
    .landing-dicipline__layout .layout__meta .layout__title {
      font-size: 14px;
      font-weight: bold; }
    .landing-dicipline__layout .layout__meta .layout__feature {
      font-size: 12px;
      font-style: italic;
      font-weight: 600;
      color: #0178B1; }
    .landing-dicipline__layout .layout__meta h1, .landing-dicipline__layout .layout__meta h2, .landing-dicipline__layout .layout__meta p, .landing-dicipline__layout .layout__meta small {
      color: #3F3F3F; }
    @media (min-width: 1024px) {
      .landing-dicipline__layout .layout__meta {
        margin-top: auto;
        padding-right: 0;
        position: absolute;
        left: 297px;
        top: 163px; }
        .landing-dicipline__layout .layout__meta h1, .landing-dicipline__layout .layout__meta h2 {
          margin-bottom: 9px; } }
    .landing-dicipline__layout .layout__meta p {
      font-size: 14px;
      margin: 0; }

.landing-other {
  background: #fff;
  padding: 30px 0;
  display: none; }
  @media (min-width: 1024px) {
    .landing-other {
      display: block; } }
  .landing-other__headline {
    font-size: 30px;
    line-height: 56px;
    text-align: center; }

.landing-others__item {
  text-align: center;
  padding: 20px 50px 15px 50px; }

.landing-others__cover {
  width: 103px;
  height: 130px;
  display: block;
  margin: auto; }

.landing-others__title {
  font-size: 14px;
  line-height: 24px;
  font-weight: bold; }

.landing-others.link-list .slick-dots {
  margin: auto; }
  .landing-others.link-list .slick-dots .slick-active button {
    background-color: #007db8 !important; }

.landing-others.link-list button.slick-prev {
  left: 10px;
  top: calc(50% - 70px); }

.landing-others.link-list button.slick-next {
  right: 10px;
  top: calc(50% - 70px); }

.list-list-holder > div, .link-list {
  text-align: center; }
  @media (min-width: 1024px) {
    .list-list-holder > div, .link-list {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      align-content: center; } }
  .list-list-holder > div .link-list__item, .link-list .link-list__item {
    padding: 30px;
    margin-top: 30px;
    background: #fff;
    border-radius: 3px;
    display: block;
    position: relative;
    color: #7a7a7a;
    text-decoration: none;
    min-height: 366px; }
    @media (min-width: 1024px) {
      .list-list-holder > div .link-list__item, .link-list .link-list__item {
        box-shadow: 6px 2px 16px 12px rgba(0, 0, 0, 0.12);
        width: calc(33.333333% - 20px); } }
    .list-list-holder > div .link-list__item .list-link__meta, .link-list .link-list__item .list-link__meta {
      position: relative;
      transition: margin-top ease-in-out 0.2s; }
    .list-list-holder > div .link-list__item .btn, .link-list .link-list__item .btn {
      margin-top: 20px; }
    @media (min-width: 1024px) {
      .list-list-holder > div .link-list__item:hover, .link-list .link-list__item:hover {
        text-decoration: none; }
        .list-list-holder > div .link-list__item:hover .list-link__meta, .link-list .link-list__item:hover .list-link__meta {
          margin-top: -40px; }
        .list-list-holder > div .link-list__item:hover img, .link-list .link-list__item:hover img {
          opacity: 0.4; }
        .list-list-holder > div .link-list__item:hover .btn, .link-list .link-list__item:hover .btn {
          opacity: 1; }
      .list-list-holder > div .link-list__item .btn, .link-list .link-list__item .btn {
        opacity: 0;
        position: absolute;
        margin: 0;
        bottom: 20px;
        left: 50%;
        margin-left: -69px; } }
    .list-list-holder > div .link-list__item img, .link-list .link-list__item img {
      margin: 0 auto 20px auto;
      transition: opacity ease-in-out 0.2s; }
    .list-list-holder > div .link-list__item small, .link-list .link-list__item small {
      display: block;
      text-transform: uppercase;
      font-size: 12px;
      color: #7a7a7a; }
    .list-list-holder > div .link-list__item p, .link-list .link-list__item p {
      font-weight: 600;
      color: #007db8;
      font-size: 18px;
      line-height: 1.3em;
      margin: 0; }
      @media (min-width: 1024px) {
        .list-list-holder > div .link-list__item p, .link-list .link-list__item p {
          font-size: 22px; } }

.list-link__cta {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  white-space: nowrap; }
  .list-link__cta a.btn {
    opacity: 1 !important;
    position: relative !important;
    left: auto !important;
    margin-left: auto !important;
    bottom: auto !important;
    margin-top: 20px !important; }

/*
Footer

The main footer at the bottom of every page

Markup:
footer.footer
  a(href="http://cengage.com")
    img(src="/images/footer/logo.png",width="130",height="40")
  ul
    li
      a(href="https://www.cengage.com/privacy") Privacy
    li
      a(href="https://www.cengage.com/terms") Terms
    li
      a(href="https://www.cengage.com/us/piracy_report.html") Piracy
    li
      a(href="http://www.cengage.com/repfinder/") Contact Us  

Styleguide 2.2.0

*/
footer {
  background: #000000;
  color: #FFFFFF;
  text-align: center;
  padding: 20px 0 20px;
  width: 100%; }
  @media (min-width: 1024px) {
    footer {
      padding: 40px 0;
      box-shadow: none;
      position: relative;
      bottom: auto;
      left: auto; } }
  footer a {
    color: #AFB0B2;
    transition: color ease-in-out 0.3s;
    cursor: pointer;
    text-decoration: none; }
    footer a:hover {
      color: #007db8;
      text-decoration: underline; }
  footer ul {
    list-style: none;
    padding: 0;
    margin: 0; }
    footer ul li {
      display: inline-block;
      margin: 5px;
      font-size: 13px; }

/*
Bumpers

Bumpers section(default, with tooltips)

Markup:
.bumper
  h2 Plan your course now, with full access to MindTap Biology.
  p
    a.btn.btn--large(href="#0") Log In
  p Don’t have an account?&nbsp;&nbsp;&nbsp;
    a(href="#0") Sign me up

Styleguide 2.3.

*/
.bumper {
  color: #FFFFFF;
  background-color: #3f3f3f;
  position: relative;
  text-align: center;
  padding: 30px 30px 25px;
  overflow: hidden;
  line-height: normal;
  display: none; }
  .bumper a:not([class]) {
    color: #F7F7F7; }
  .bumper h2 {
    font-size: 30px; }
  .bumper .bumper__title {
    font-size: 30px;
    color: #FFFFFF; }
  .bumper .btn {
    margin: 20px 10px;
    position: relative; }
  .bumper__link {
    text-decoration: underline;
    color: #FFFFFF; }
  @media (min-width: 1024px) {
    .bumper {
      display: block;
      padding: 60px 250px; } }

.search-page-content {
  margin-bottom: 60px;
  padding-top: 30px;
  padding-bottom: 10px; }
  .search-page-content:before, .search-page-content:after {
    content: '';
    display: table; }
  .search-page-content:after {
    clear: both; }
  .search-page-content .screen-reader-text {
    position: absolute;
    margin-left: -9999px; }
  .search-page-content input[type=text] {
    width: calc(100% - 130px);
    font-size: 14px;
    padding: 6px 3px 6px 30px;
    float: left; }
  .search-page-content .btn {
    float: right; }
  .search-page-content .searchform {
    position: relative; }
    .search-page-content .searchform:after {
      position: absolute;
      top: 8px;
      left: 8px;
      color: #7a7a7a;
      content: "\EA13";
      font-family: 'coastblog-icons' !important;
      font-style: normal !important;
      font-weight: normal !important;
      font-variant: normal !important;
      text-transform: none !important;
      speak: none;
      line-height: 1;
      vertical-align: middle;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale; }
  @media (min-width: 1024px) {
    .search-page-content {
      padding-top: 40px;
      padding-bottom: 10px; } }

/*
Sampe page

The landing page for the sample excluding any of the sample chapter or assessment markup

Markup:
.container
  .row
    .col8
      a.link-back(href="/") Learn more about MindTap for Biology
      .book-meta
        img.book-meta__cover(src="/images/isbn_resources/1111577730/cover.jpg",width="101",height="122")
        small.book-meta__mice Featured Digital Sample
        h2.book-meta__title Biology: The Dynamic Science 4th Edition
        p.book-meta__author by Peter J. Russel and Paul E. Hertz
        strong.book-meta__isbn ISBN: 1111577730
        ul.book-meta__links
          li
            a View TOC
          li
            a View other biology textbooks
    .col4
      .login-cta
        h4 Access the full book in MindTap right now!
        a.btn.btn--large(href="") Log in to MindTap
        p Don't have an account?
          a(href="") Sign up now

Styleguide 2.5.0

*/
.sample-header {
  margin-bottom: 40px;
  margin-top: 40px; }

.link-back {
  color: #007db8;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  position: relative;
  padding-left: 16px; }
  .link-back:hover {
    text-decoration: underline; }
  .link-back:before {
    content: '\EA05';
    font-family: 'cengage-icons' !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    vertical-align: middle;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 11px;
    position: absolute;
    margin-right: 10px;
    left: 0;
    top: 7px;
    text-decoration: none !important; }

.book-meta {
  margin-top: 20px;
  position: relative;
  padding-left: 130px;
  line-height: 1.8em; }
  .book-meta__cover {
    position: absolute;
    left: 0;
    top: 6px;
    max-width: 101px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15); }
  .book-meta__mice {
    display: block;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 12px;
    color: #7a7a7a; }
  .book-meta__title {
    margin: 0 0 5px 0;
    line-height: 1.2em; }
  .book-meta__author {
    margin: 0;
    font-size: 14px;
    color: #3f3f3f; }
  .book-meta__isbn {
    margin: 0;
    font-weight: 600;
    font-size: 14px; }
  .book-meta__links {
    list-style: none;
    margin: 5px 0 0 0;
    padding: 0;
    line-height: 1em;
    font-size: 14px; }
    .book-meta__links li {
      display: inline-block;
      padding: 0 5px 0 8px;
      border-left: 1px solid #7a7a7a; }
      .book-meta__links li:first-child {
        border-left: none;
        padding-left: 0; }
      .book-meta__links li a {
        color: #007db8;
        text-decoration: none; }
        .book-meta__links li a:hover {
          text-decoration: underline; }

.login-cta {
  background-color: #F7F7F7;
  padding: 30px;
  text-align: center; }
  .login-cta h4 {
    margin: 0 50px 20px 50px; }
  .login-cta p {
    margin-bottom: 0;
    font-size: 14px;
    padding: 0 50px; }
    .login-cta p a {
      color: #007db8;
      text-decoration: none; }
      .login-cta p a:hover {
        text-decoration: underline; }

/*
Interactive Samples

The interactive eReader and Assessment tools tab activation

Markup:
.container
  .row
    .col4
      ul.tool-list
        li.active
          a(href="#")
            span.icon.icon--book
            strong Sample Chapter
            p Check out a sample chapter from the MindTap Reader.
        li
          a(href="#")
            span.icon.icon--grad-hat
            strong Assessments
            p Try a sample activity with this assessments demo.
      hr.thin-stroke
      .mindtap-benefits
        h5.mindtap-benefits__title Benefits of MindTap for Biology
        ul.mindtap-benefits__video-list
          li
            a Personalized Support
          li
            a Realtime Analytics
          li
            a Mobile Optimized
          li
            a Conceptual Learning
          li
            a Specialized Learning Paths

Styleguide 2.6.0

*/
.tool-list {
  list-style: none;
  padding: 0;
  margin: 0; }
  .tool-list li a {
    display: block;
    position: relative;
    padding: 20px 20px 20px 70px;
    text-decoration: none;
    display: block;
    color: #000000; }
    .tool-list li a:hover {
      background-color: #F7F7F7; }
    .tool-list li a.active {
      background: #DFDFDF; }
      .tool-list li a.active .icon, .tool-list li a.active strong {
        color: #000000; }
      .tool-list li a.active:after {
        content: '';
        display: block;
        position: absolute;
        right: -25px;
        top: calc(50% - 25px);
        width: 0;
        height: 0;
        border-top: 25px solid transparent;
        border-bottom: 25px solid transparent;
        border-left: 25px solid #DFDFDF;
        z-index: 1; }
  .tool-list li .icon, .tool-list li strong {
    color: #007db8; }
  .tool-list .icon {
    position: absolute;
    left: 20px;
    top: 23px;
    font-size: 25px; }
  .tool-list p {
    margin: 5px 0;
    font-size: 12px; }

.thin-stroke {
  border: none;
  border-top: 1px solid #F7F7F7; }

.mindtap-benefits {
  margin: 30px 20px; }
  .mindtap-benefits__title {
    color: #7a7a7a;
    text-transform: uppercase;
    font-size: 12px;
    margin: 0; }
  .mindtap-benefits__video-list {
    list-style: none;
    margin: 10px 0;
    padding: 0; }
    .mindtap-benefits__video-list a {
      color: #007db8;
      font-weight: 600;
      text-decoration: none;
      line-height: 32px;
      display: block; }
      .mindtap-benefits__video-list a:hover {
        text-decoration: underline; }
    .mindtap-benefits__video-list small {
      font-size: 14px; }

.interactive-tools {
  background: #3F3F3F;
  padding: 30px 50px 0 50px;
  display: none;
  color: #fff;
  position: relative; }
  .interactive-tools__header {
    margin-bottom: 30px; }
  .interactive-tools__title {
    margin: 0; }
  .interactive-tools__sub {
    font-size: 14px;
    font-weight: 600; }
    .interactive-tools__sub ul {
      list-style: none;
      padding: 0;
      margin: 0; }
      .interactive-tools__sub ul li {
        border-left: 1px solid #fff;
        padding: 0 10px;
        display: inline-block; }
        .interactive-tools__sub ul li:first-child {
          border-left: none;
          padding-left: 0; }
        .interactive-tools__sub ul li a {
          text-decoration: underline;
          cursor: pointer; }
          .interactive-tools__sub ul li a.active {
            text-decoration: none; }
  .interactive-tools a.interactive-tools__sub {
    text-decoration: underline;
    cursor: pointer; }
  .interactive-tools.active {
    display: block; }
  .interactive-tools .btn {
    margin-top: 10px; }
  .interactive-tools iframe {
    min-width: 100%;
    height: 700px;
    border: none; }

/*
Other Modal

Other Textbooks modal

Markup:
.modal
  .modal__backdrop
  .modal__body
    .modal__header
      h1.modal__headline Biology Textbooks MindTap Supports
    .modal__content
      ul.book-list
        li.book-list__item
          .book-meta
            img.book-meta__cover(src="/images/isbn_resources/1111577730/cover.jpg",width="80",height="97")
            h2.book-meta__title Biology: The Dynamic Science 4th Edition
            p.book-meta__author by Peter J. Russel and Paul E. Hertz
            strong.book-meta__isbn ISBN: 1111577730
        li.book-list__item
          .book-meta
            img.book-meta__cover(src="/images/isbn_resources/1111577730/cover.jpg",width="80",height="97")
            h2.book-meta__title Biology: The Dynamic Science 4th Edition
            p.book-meta__author by Peter J. Russel and Paul E. Hertz
            strong.book-meta__isbn ISBN: 1111577730
        li.book-list__item
          .book-meta
            img.book-meta__cover(src="/images/isbn_resources/1111577730/cover.jpg",width="80",height="97")
            h2.book-meta__title Biology: The Dynamic Science 4th Edition
            p.book-meta__author by Peter J. Russel and Paul E. Hertz
            strong.book-meta__isbn ISBN: 1111577730
        li.book-list__item
          .book-meta
            img.book-meta__cover(src="/images/isbn_resources/1111577730/cover.jpg",width="80",height="97")
            h2.book-meta__title Biology: The Dynamic Science 4th Edition
            p.book-meta__author by Peter J. Russel and Paul E. Hertz
            strong.book-meta__isbn ISBN: 1111577730

Styleguide 2.7.0

*/
.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 9999; }
  .modal__backdrop {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); }
  .modal__body {
    background: #fff;
    position: absolute;
    width: calc(100% - 50px);
    max-width: 80em;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    border-radius: 3px;
    height: 90%; }
    .modal__body.model__body-fluid {
      height: auto;
      max-height: 90%; }
  .modal__close {
    position: absolute;
    top: 23px;
    right: 23px;
    text-decoration: none;
    cursor: pointer;
    color: #7a7a7a; }
    .modal__close a {
      color: #7a7a7a;
      text-decoration: none; }
  .modal__header {
    width: 100%;
    background: #F8F8F8;
    border-bottom: 1px solid #ddd;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px; }
  .modal__headline {
    font-size: 22px;
    text-align: center;
    margin: 0; }
  .modal--skinny .modal__body {
    max-width: 40em; }
  .modal--enter {
    opacity: 0; }
    .modal--enter .modal__body {
      top: 60%; }
  .modal--enter-active {
    opacity: 1;
    transition: opacity 250ms ease; }
    .modal--enter-active .modal__body {
      top: 50%;
      transition: top 100ms ease; }
  .modal--leave {
    opacity: 1; }
    .modal--leave .modal__body {
      top: 50%; }
  .modal--leave-active {
    opacity: 0;
    transition: opacity 250ms ease; }
    .modal--leave-active .modal__body {
      top: 60%;
      transition: top 100ms ease; }
  .modal__content {
    height: calc(100% - 75px);
    overflow: auto; }

.book-list {
  list-style: none;
  margin: 0;
  padding: 0; }
  .book-list__item {
    padding: 0;
    border-top: 1px solid #ddd;
    min-height: 150px; }
    .book-list__item:first-child {
      border-top: 1px solid transparent; }
  .book-list .book-meta__title {
    font-size: 16px; }
  .book-list .book-meta {
    padding-left: 125px; }
    .book-list .book-meta__cover {
      left: 20px; }
    .book-list .book-meta__author {
      font-size: 14px; }

.styleguide-element .modal {
  position: relative; }
  .styleguide-element .modal__backdrop {
    position: relative; }
  .styleguide-element .modal__body {
    position: relative;
    transform: none;
    left: 0;
    top: 0;
    width: 100%; }
  .styleguide-element .modal__content {
    position: relative; }

/*
TOC Modal

Table of contents modal

Markup:
.modal
  .modal__backdrop
  .modal__body
    .modal__close
      a.icon.icon--close
    .modal__header
      h1.modal__headline Table of Contents
    .modal__content
      ol.toc-list
        h3.toc-list__section Introduction
        li Introduction to Biological Concepts and Research.
        h3.toc-list__section Unit One: Molecules and Cells
        li Life, Chemistry, and Water.
        li Biological Molecules: The Carbon Compounds of Life.
        li Cells.
        li Membranes and Transport.
        li Energy, Enzymes, and Biological Reactions.
        li Photosynthesis.
        li Cell Communication.
        li Cell Division and Mitosis.
        h3.toc-list__section Unit Two: Genetics
        li Melosis: The Cellular Basis of Sexual Reproduction.
        li Mendel, Genes, and Inheritance.
        li Genes, Chromosomes, and Human Genetics.

Styleguide 2.7.1

*/
.toc-list li {
  margin: 5px 0 5px 40px; }

.toc-list__section {
  font-size: 16px;
  margin: 1em 0 .5em; }

/*
Video Modal

MindTap Video Features Modal

Markup:
.modal
  .modal__backdrop
  .modal__body
    .modal__header
      h1.modal__headline MindTap Reader Features
    .modal__content
      .row.row-no-margins
        .col6
          .videoWrapper
            iframe(width="640",height="360",src="https://www.youtube.com/embed/dQw4w9WgXcQ",frameborder="0",allowfullscreen)
        .col6
          h3.feature--title These features allow you to:
          ul.features-list
            li Empower stuents with the ability to create their own study guide
            li Build and personalize your course to teach biology the way you want to.
            li Offer additional resources to help students with difficult concepts.
          h3.feature--title See MindTap in Action:
          ul.action-list
            li
              span Highlight Text
            li.active
              span Adding a Note
            li
              span Adding a Video
            li
              span Bookmark a Page
            li
              span Text-to-Speech

Styleguide 2.7.2

*/
.feature--title {
  color: #7a7a7a; }

.features-list {
  list-style: none;
  padding: 0; }
  .features-list li {
    font-size: 12px;
    margin: 5px 0;
    padding-left: 22px;
    padding-right: 10px;
    position: relative; }
    .features-list li:before {
      font-family: 'cengage-icons' !important;
      font-style: normal !important;
      font-weight: normal !important;
      font-variant: normal !important;
      text-transform: none !important;
      speak: none;
      line-height: 1;
      vertical-align: middle;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: "\EA09";
      margin-right: 8px;
      color: #90B82E;
      position: absolute;
      left: 0;
      top: 6px; }

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  padding-top: 25px;
  height: 0; }

.videoWrapper iframe, .videoWrapper video {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 30px);
  height: 100%; }

.action-list {
  list-style: none;
  padding: 0; }
  .action-list li {
    margin: 7px 0; }
  .action-list a {
    text-decoration: none;
    color: #007db8;
    font-size: 14px;
    font-weight: bold; }
    .action-list a:before {
      font-family: 'cengage-icons' !important;
      font-style: normal !important;
      font-weight: normal !important;
      font-variant: normal !important;
      text-transform: none !important;
      speak: none;
      line-height: 1;
      vertical-align: middle;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: "\EA11";
      margin-right: 8px;
      position: relative;
      top: -1px; }
    .action-list a.active {
      color: #000; }

/*
Tours

The tour modal section

Markup:
.tours
  .tours__carousel
    .tours__list
      .tours__item
        .row
          .col12.text-center
            h1.tours__title Mobile Optimized
            p.tours__page 1 of 5
        .row 
          .col5.col1-offset
            .tours__description
              h2.tours__intro Heather’s running late, and has a test.
              p.tours__text Since the MindTap reader is optimized for her handheld device, she can squeeze every minute out of her short bus ride, and study along the way.
          .col5.text-right
            img.tours__img(src="/images/tours/ph-1.jpg",width="458",height="458")
      .tours__item
        .row
          .col12.text-center
            h1.tours__title Mobile Optimized
            p.tours__page 2 of 5
        .row 
          .col5.col1-offset
            .tours__description
              h2.tours__intro Your quiz covers 200 pages. Heather has 20 minutes.
              p.tours__text That’s why she used MindTap on her tablet last night to bookmark the things she needs to spend more time on. Now she can quickly study from a select collection of pages.
          .col5.text-right
            img.tours__img(src="/images/tours/ph-1.jpg",width="458",height="458")
      .tours__item
        .row
          .col12.text-center
            h1.tours__title Mobile Optimized
            p.tours__page 3 of 5
        .row 
          .col5.col1-offset
            .tours__description
              h2.tours__intro Heather knows the bus isn’t a library. 
              p.tours__text Today it will have to do, and with MindTap’s hands free text-to-speech feature and headphones, she can hold on tight and tune out the baby beside her at the same time.
          .col5.text-right
            img.tours__img(src="/images/tours/ph-1.jpg",width="458",height="458")
      .tours__item
        .row
          .col12.text-center
            h1.tours__title Mobile Optimized
            p.tours__page 4 of 5
        .row 
          .col5.col1-offset
            .tours__description
              h2.tours__intro Heather’s lifestyle is fast paced and hectic.
              p.tours__text But she’s still at the top of her class. The workflow & learning styles of students like heather are best suited to a single, easy-to-navigate platform with realtime access to help.
          .col5.text-right
            img.tours__img(src="/images/tours/ph-1.jpg",width="458",height="458")
      .tours__item
        .row
          .col12.text-center
            h1.tours__title Mobile Optimized
            p.tours__page 5 of 5
        .row
          .col6.col3-offset
            .tours__cta.text-center
              h2.tours__intro Ready to try MindTap with a sample Biology chapter?
              a.btn.btn--large See Digital Sample
              a.btn.btn--hollow.btn--large Log in to MindTap
              br
              a.btn.btn--link No thanks, maybe later.
  script(src="//cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.js")
  script.
    $('.tours__list').slick({
      dots: true,
      infinite: false
    });
  script.
    var tour = {
      "title": "Mobile Optimized",
      "sections": [
        {
          "title": "Heather’s running late, and has a test.",
          "body": "Since the MindTap reader is optimized for her handheld device, she can squeeze every minute out of her short bus ride, and study along the way.",
          "img": "/images/tours/ph-1.jpg"
        },
        {
          "title": "Your quiz covers 200 pages. Heather has 20 minutes.",
          "body": "That’s why she used MindTap on her tablet last night to bookmark the things she needs to spend more time on. Now she can quickly study from a select collection of pages.",
          "img": "/images/tours/ph-1.jpg"
        },
        {
          "title": "Heather knows the bus isn’t a library. ",
          "body": "Today it will have to do, and with MindTap’s hands free text-to-speech feature and headphones, she can hold on tight and tune out the baby beside her at the same time.",
          "img": "/images/tours/ph-1.jpg"
        },
        {
          "title": "Heather’s lifestyle is fast paced and hectic.",
          "body": "But she’s still at the top of her class. The workflow & learning styles of students like heather are best suited to a single, easy-to-navigate platform with realtime access to help.",
          "img": "/images/tours/ph-1.jpg"
        }
      ]
    }

Styleguide 2.8.0

*/
.styleguide-element .tours {
  position: relative;
  z-index: auto; }
  .styleguide-element .tours__carousel {
    transform: none;
    top: 0;
    left: 0; }

.tours {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  background: white;
  overflow: auto; }
  .tours__carousel {
    position: relative;
    width: 100%; }
    @media (min-width: 1024px) {
      .tours__carousel {
        height: 500px;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%; } }
  .tours__list {
    list-style: none;
    /*
    position: absolute;
    margin: 0;
    padding: 0;
    left: 0;
    top: 0;*/ }
  .tours__cta {
    padding: 100px 0;
    background: url(/images/tours/bg-cta.jpg) no-repeat center center; }
  .tours__title {
    font-size: 16px;
    color: #7a7a7a;
    text-transform: uppercase;
    line-height: 1;
    margin: 30px 0 10px 0;
    display: inline-block; }
    @media (min-width: 1024px) {
      .tours__title {
        margin: 0 0 20px 0; } }
  .tours__description {
    margin-top: 20px;
    text-align: center; }
    @media (min-width: 1024px) {
      .tours__description {
        margin-top: 110px;
        padding-right: 100px;
        text-align: left; } }
  .tours__page {
    font-size: 16px;
    margin: 0;
    color: #7a7a7a; }
  .tours__intro {
    font-size: 20px; }
    @media (min-width: 1024px) {
      .tours__intro {
        font-size: 30px; } }
  .tours__text {
    font-size: 16px;
    line-height: 1.6em; }
  .tours__img-container {
    background-image: url(/images/tours/bg-spot1@2x.png);
    background-repeat: no-repeat;
    background-position: 0 center;
    background-size: contain; }
    .tours__img-container-gif {
      padding: 90px 30px; }
      @media (min-width: 1024px) {
        .tours__img-container-gif {
          padding: 90px 0; } }
  .tours .tours__list:nth-child(0n+2) .tours__img-container {
    background-image: url(/images/tours/bg-spot3@2x.png); }
  .tours .tours__list:nth-child(0n+3) .tours__img-container {
    background-image: url(/images/tours/bg-spot2@2x.png); }
  .tours__img {
    max-width: 100%;
    height: auto;
    display: block; }

.tours__carousel button {
  cursor: pointer; }
  .tours__carousel button.slick-next, .tours__carousel button.slick-prev {
    appearance: none;
    background: transparent;
    border: none;
    position: absolute;
    top: 50%;
    color: transparent;
    z-index: 9;
    font-size: 1px; }
  .tours__carousel button.slick-next:before, .tours__carousel button.slick-prev:before {
    z-index: 1000;
    font-size: 30px !important;
    line-height: 1;
    display: block;
    opacity: 1 !important;
    color: #7a7a7a;
    font-family: "cengage-icons" !important; }
  .tours__carousel button.slick-next.slick-disabled:before, .tours__carousel button.slick-prev.slick-disabled:before {
    opacity: 0 !important; }
  .tours__carousel button.slick-prev {
    left: 0 !important; }
    @media (min-width: 1024px) {
      .tours__carousel button.slick-prev {
        left: 25px !important; } }
  .tours__carousel button.slick-next {
    right: 0 !important; }
    @media (min-width: 1024px) {
      .tours__carousel button.slick-next {
        right: 25px !important; } }
  .tours__carousel button.slick-next:before {
    content: "" !important; }
  .tours__carousel button.slick-prev:before {
    content: "" !important; }

.slick-dots {
  bottom: 25px !important; }

.slick-dots li {
  margin: 0 !important; }

ul.slick-dots li button {
  background-color: #D8D8D8 !important;
  width: 9px;
  height: 9px; }

ul.slick-dots li.slick-active button {
  background-color: #007db8 !important; }

/*
Buttons

Buttons

Markup:
<a href="" class="btn {$modifiers}">Button</a>

.btn--hollow	    - Hollow button
.btn--hollow-white	- Hollow button
.btn--blue		    - Blue button
.btn--block		    - Block button
.btn--small		    - Small button
.btn--plain		    - Plain button

Styleguide 1.3.

*/
.btn {
  color: #FFFFFF;
  background-color: #90b82e;
  transition: all 0.2s ease;
  border-width: 0;
  border-style: none;
  font-size: 14px;
  font-weight: 600;
  display: inline-block;
  text-align: center;
  padding: 7px 20px;
  height: auto;
  text-decoration: none;
  border-radius: 3px;
  cursor: pointer;
  border-color: transparent;
  white-space: normal; }
  .btn > span.icon {
    margin-right: 10px;
    font-size: 0.6em;
    line-height: 1;
    position: relative;
    top: -1px; }
  .btn:active {
    box-shadow: none; }
  @media (min-width: 1024px) {
    .btn:hover {
      background-color: #a0cc33; }
    .btn:active {
      background-color: #80a429; } }
  .btn--secondary {
    background-color: #007db8; }
    .btn--secondary:hover {
      background-color: #00a0eb; }
    .btn--secondary:active {
      background-color: #005a85; }
  .btn--link {
    background-color: transparent;
    color: #007db8;
    border: none;
    font-weight: normal; }
    .btn--link:hover {
      background-color: transparent; }
  .btn--orange {
    background-color: #FA6600; }
    .btn--orange:hover {
      background-color: #ff832e; }
    .btn--orange:active {
      background-color: #e15c00; }
  .btn--hollow {
    color: #FFFFFF;
    background-color: #90b82e;
    transition: all 0.2s ease;
    border-width: 0;
    border-style: none;
    font-size: 14px;
    font-weight: 600;
    display: inline-block;
    text-align: center;
    padding: 7px 20px;
    height: auto;
    text-decoration: none;
    border-radius: 3px;
    cursor: pointer;
    border-color: transparent;
    white-space: normal;
    padding: 6px 29px 6px;
    background: #fff;
    color: #90b82e;
    border: 1px solid #90b82e;
    box-shadow: none; }
    .btn--hollow > span.icon {
      margin-right: 10px;
      font-size: 0.6em;
      line-height: 1;
      position: relative;
      top: -1px; }
    .btn--hollow:active {
      box-shadow: none; }
    @media (min-width: 1024px) {
      .btn--hollow:hover {
        background-color: #a0cc33; }
      .btn--hollow:active {
        background-color: #80a429; } }
    @media (min-width: 1024px) {
      .btn--hollow:hover {
        background-color: #007db8;
        color: #FFFFFF;
        border-color: transparent;
        text-decoration: underline; } }
    .btn--hollow.btn--large {
      padding: 12px 49px 14px;
      margin: 15px; }
    .btn--hollow:hover {
      background-color: #80a429;
      color: #FFFFFF; }
    .btn--hollow.btn--secondary {
      border-color: #007db8;
      color: #007db8; }
      .btn--hollow.btn--secondary:hover {
        background: transparent; }
  .btn--small {
    padding: 4px 17px 5px; }
  .btn--large {
    padding: 13px 30px 15px;
    font-size: 16px; }
