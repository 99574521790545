/*
Footer

The main footer at the bottom of every page

Markup:
footer.footer
  a(href="http://cengage.com")
    img(src="/images/footer/logo.png",width="130",height="40")
  ul
    li
      a(href="https://www.cengage.com/privacy") Privacy
    li
      a(href="https://www.cengage.com/terms") Terms
    li
      a(href="https://www.cengage.com/us/piracy_report.html") Piracy
    li
      a(href="http://www.cengage.com/repfinder/") Contact Us  

Styleguide 2.2.0

*/

$footer-padding: 40px;
footer {
  background: map-get($colors,black);
  color: map-get($colors,white);
  text-align: center;
  padding: 20px 0 20px;

  width: 100%;
  @media #{$desktop-up} {
    padding: $footer-padding 0;
    box-shadow: none;

    //To counter fixed footer
    position: relative;
    bottom: auto;
    left: auto;
  }
  a {
    color: #AFB0B2;
    transition: color ease-in-out 0.3s;
    cursor: pointer;
    text-decoration: none;
    &:hover {
      color: $brand-primary;
      text-decoration: underline;
    }
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      display: inline-block;
      margin: 5px;
      font-size: 13px;
    }
  }
}
